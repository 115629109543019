import React from "react";
import "../../App.scss";
import "./AboutUs.scss";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useRef } from "react";
import { Link } from "react-router-dom";
import Calendar from "../../components/library/calendar";
import { Mixpanel } from '../../mixpanel';
import BannerCalendarWrapper from "../../components/library/calendarWrapper/BannerCalendarWrapper";
function AboutUs() {
  Mixpanel.track('about-us pageview');

  return (
    <>
      <div className="gradient-top">
        <div className="Aboutus-container">
          {/* how-we-are */}
          <div className="how-we-are container row">
            <div className="col-7">
              <div className="left-side">
                <img src="/images/all/team-web.png" />
              </div>
              <div className="right-side">
                <h2 className="title">מי אנחנו?</h2>

                <div className="sup-title">
                  <p>
                    <img
                      className="better-min"
                      src="/images/all/Betterlogo-min.svg"
                    />
                    הוקמה על ידי מומחי פיננסים וטכנולוגיה, כל אחד עם יותר מ-15
                    שנות ניסיון.
                  </p>
                  <p>
                    אנחנו האנשים שכל המשפחה והחברים פונים אליהם עם שאלות על הכסף
                    - מה לעשות עם הפנסיה, האם למחזר את המשכנתא, איך להשקיע את
                    החסכונות וכו'. כל היום קיבלנו לוואטסאפ מכתבים מהבנק שחברים
                    שלנו לא הבינו, שאלו אותנו מה לעשות כששער הדולר יורד או
                    כשהאינפלציה עולה, ותמיד עזרנו לכולם על הדרך.
                  </p>
                  <p>
                    בסוף נמאס לנו לעשות את זה בצורה ידנית והחלטנו להקים את{" "}
                    <img
                      className="better-min"
                      src="/images/all/Betterlogo-min.svg"
                    />{" "}
                    פיתחנו טכנולוגיה ייחודית שעושה את כל העבודה הזאת עבור
                    הלקוחות שלנו, כך שהם לא צריכים לעשות כלום:
                  </p>
                  <div className="mobile-img">
                    <img
                      src="/images/all/team.png"
                      alt="circular"
                    />
                  </div>
                  <p>
                    אנחנו משיגים את המידע וסורקים בכל רגע נתון איך אפשר לחסוך
                    כסף בכל מקום - במס, בביטוח, בפנסיה, במשכנתא, בהשקעות, ובגדול
                    - בכל תחום שקשור לפיננסים. ברגע שזיהינו פוטנציאל לחיסכון
                    אנחנו מבקשים מהלקוח אישור כדי לבצע עבורו את הפעולה, בלי
                    שיידרש לעשות שום דבר חוץ מלחתום דרך הטלפון, וכל זה בשקיפות
                    ובזמינות מלאה אליכם בוואטסאפ ובטלפון.
                  </p>
                  <p>
                    חסכנו עד היום סכום ממוצע של 200,000 ש"ח ללקוח בעמלות ובדמי
                    ניהול, ולכן אנחנו מניחים שנוכל למצוא כאלה חסכונות גם
                    בשבילכם.
                  </p>
                  <span>
                    רוצים להכיר? תוכלו לתאם איתנו{" "}
                    <br className="mobile-brake" />
                    <AnchorLink offset='88' href='#zoom-schedule'>שיחה קצרה בזום</AnchorLink>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* members */}
          <div className="members container row">
            <div className="col-3">
              <h2>טל עקרוני</h2>
              <h3> מנכ"ל</h3>
              <p>
                מומחה לחינוך פיננסי בעל ניסיון של 15 שנים. הקים 5 תכניות לאומיות לחינוך פיננסי, מרצה לכלכלה במכללה למינהל, ניהל את המוצרים הפיננסים בארגון "חבר" ומייסד של 3 סטארטאפים בתעשיית הפינטק.
              </p>
            </div>

            <div className="col-3">
              <h2>ערן אורנשטיין</h2>
              <h3> סמנכ"ל תפעול</h3>
              <p>
                מומחה להשקעות ולפנסיה בעל ניסיון של 25 שנים. כיהן כמנכ"ל חברת ניהול תיקי השקעות מובילה, מנכ"ל חברת ייעוץ פנסיוני וחבר דירקטוריון בקופת הגמל דש-איפקס. בעל רישיון פנסיוני ממשרד האוצר.
              </p>
            </div>
            <div className="col-3">
              {" "}
              <h2>אורי וייס</h2>
              <h3> סמנכ"ל פיתוח</h3>
              <p>
                מומחה לטכנולוגיה פיננסית בעל ניסיון של 15 שנים. הקים 2 חברות טכנולוגיה, בעל תואר שני בהנדסת חשמל ובעלים של בית תוכנה מצליח המעניק שירותים לחברות הייטק מובילות בישראל ובעולם.
              </p>
            </div>
          </div>
        </div>
      </div>
      <BannerCalendarWrapper Calendar={Calendar} image="/images/all/Security-Calendar.png" />
    </>
  );
}

export default AboutUs;
