import React, { useState } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import ReactModal from 'react-modal';

function Footer() {
    const [modalSrc, setModalSrc] = useState();
    const [iframeSrc, setIframeSrc] = useState();
    const [iframeHeight, setIframeHeight] = useState();


    const showModalImage = (src) => {
        setModalSrc(src);
    }

    const showModalIframe = (src, iframeHeight) => {
        setIframeSrc(src);
        setIframeHeight(iframeHeight)
    }

    return (
        <div className="footer-container">
            <div className="footer-top container">
                {/* logo */}
                <div className="footer-logo-wrap">
                    <div className="footer-logo">
                        <img src=" /images/all/Betterlogo-larg.svg" />
                    </div>
                    <div className="footer-column-logo small-text-color">
                        {/*<Link to="/">המשהו הפיננסי שלך</Link>*/}
                        <Link className="none-mobile" to="/AboutUs">
                            מי אנחנו
                        </Link>
                        <Link className="none-mobile" to="/SecurityandPrivacy">
                            אבטחה ופרטיות
                        </Link>
                        {/*<Link className="none-mobile" to="/">*/}
                        {/*  Careers*/}
                        {/*</Link>*/}
                    </div>
                    <hr className="brak-line" />
                </div>
                {/* links */}
                <div className="footer-links-wrap none-mobile">
                    <h2 className="footer-links-title ">מידע ללקוח</h2>
                    <div className="footer-column ">
                        <Link to="/HowItWorks">איך זה עובד?</Link>
                        <Link to="/FAQ">שאלות ותשובות</Link>
                        <Link to="/Companies">שירות לחברות</Link>
                        <Link to="/Contact">צרו קשר</Link>
                        <a
                            href="https://www.comeet.com/jobs/allbetter/F8.00E"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Careers
                        </a>
                    </div>
                </div>
                {/* info */}
                <div className="footer-info-wrap">
                    <h2 className="footer-info-title">רשיונות ומפקחים פיננסיים</h2>
                    <div className="footer-column f-t">
                        <span onClick={() => showModalImage('/images/all/docs/power-of-attorney.png')}>
                            {" "}
                            <img src="/images/all/footer-info-icon.png" className="none-mobile" />
                            מרשם מיופי כוח בתמורה של בנק ישראל - מיופה כוח בתמורה
                        </span>
                        <span onClick={() => showModalImage('/images/all/docs/better-pension-license.jpg')}>
                            {" "}
                            <img src="/images/all/footer-info-icon.png" className="none-mobile" />
                            רישיון שוק ההון, ביטוח וחיסכון - בעל רישיון פנסיוני
                        </span>
                        {/*<Link to="/">*/}
                        {/*  {" "}*/}
                        {/*  <img src="/images/all/footer-info-icon.png" className="none-mobile" />*/}
                        {/*  רישיון הרשות לניירות ערך - נותן שירותי מידע פיננסי*/}
                        {/*</Link>*/}
                        {/*<span onClick={()=>showModalImage('/images/all/docs/better-pension-license.jpg')}>*/}
                        {/*    {" "}*/}
                        {/*    <img src="/images/all/footer-info-icon.png" className="none-mobile"/>*/}
                        {/*    רישיון מס הכנסה - חברת ייעוץ מס*/}
                        {/*</span>*/}
                        <span onClick={() => showModalImage('/images/all/docs/tax-license.jpg')}>
                            {" "}
                            <img src="/images/all/footer-info-icon.png" className="none-mobile" />
                            מועצת יועצי המס - רישיון יועץ מס
                        </span>
                        <span onClick={() => showModalImage('/images/all/docs/registered-database.png')}>
                            {" "}
                            <img src="/images/all/footer-info-icon.png" className="none-mobile" />
                            משרד המשפטים - מאגר מידע רשום
                        </span>
                    </div>
                </div>
                <div className="social-media-wrap">
                    <div className="social-icons">
                        {/*<Link*/}
                        {/*  className="social-icon-link instagram"*/}
                        {/*  to="/"*/}
                        {/*  target="_blank"*/}
                        {/*  aria-label="Instagram"*/}
                        {/*>*/}
                        {/*  <i className="fab fa-instagram" />*/}
                        {/*</Link>*/}
                        <Link
                            className="social-icon-link twitter"
                            to={{ pathname: "https://www.linkedin.com/company/betterpfm" }}
                            target="_blank"
                            aria-label="LinkedIn"
                        >
                            <i className="fab fa-linkedin" />
                        </Link>
                        {/*<Link*/}
                        {/*  className="social-icon-link twitter"*/}
                        {/*  to="/"*/}
                        {/*  target="_blank"*/}
                        {/*  aria-label="Twitter"*/}
                        {/*>*/}
                        {/*  <i className="fab fa-twitter" />*/}
                        {/*</Link>*/}
                        <Link
                            className="social-icon-link facebook"
                            to={{ pathname: "https://www.facebook.com/people/Better/100086255880240/" }}
                            target="_blank"
                            aria-label="Facebook"
                        >
                            <i className="fab fa-facebook-f" />
                        </Link>
                    </div>
                </div>
            </div>
            <hr></hr>
            {/* mobile social media icone */}
            <div className="social-media-mobile container">
                <div className="social-icons-mobile">
                    {/*<Link*/}
                    {/*  className="social-icon-link instagram"*/}
                    {/*  to="/"*/}
                    {/*  target="_blank"*/}
                    {/*  aria-label="Instagram"*/}
                    {/*>*/}
                    {/*  <i className="fab fa-instagram" />*/}
                    {/*</Link>*/}
                    <Link
                        className="social-icon-link twitter"
                        to={{ pathname: "https://www.linkedin.com/company/betterpfm" }}
                        target="_blank"
                        aria-label="LinkedIn"
                    >
                        <i className="fab fa-linkedin" />
                    </Link>
                    {/*<Link*/}
                    {/*  className="social-icon-link twitter"*/}
                    {/*  to="/"*/}
                    {/*  target="_blank"*/}
                    {/*  aria-label="Twitter"*/}
                    {/*>*/}
                    {/*  <i className="fab fa-twitter" />*/}
                    {/*</Link>*/}
                    <Link
                        className="social-icon-link facebook"
                        to={{ pathname: "https://www.facebook.com/people/Better/100086255880240/" }}
                        target="_blank"
                        aria-label="Facebook"
                    >
                        <i className="fab fa-facebook-f" />
                    </Link>
                </div>
            </div>
            <div className="footer-bottom container">
                <div className="footer-bottom-items none-mobile">
                    <span onClick={() => showModalIframe('https://better-website-static.s3.eu-central-1.amazonaws.com/better-terms-of-use.html', 7250)}>תנאי שימוש</span>
                    <span className="dot">&#8226;</span>
                    <span onClick={() => showModalIframe('https://better-website-static.s3.eu-central-1.amazonaws.com/better-privacy.html', 2200)}>מדיניות פרטיות</span>
                    <span className="dot none-mobile">&#8226;</span>
                    <span>בטר ניהול פיננסי אישי בע״מ</span>
                    <span className="dot none-mobile">&#8226;</span>
                    <span>דרך בגין 114, תל אביב-יפו</span>
                    <span className="dot">&#8226;</span>
                    <span>03-5703080</span>
                </div>
                <div className="footer-bottom-items-mobile">
                    <div>
                        <span>דרך בגין 114, תל אביב-יפו</span>
                        <span className="dot-mobile">&#8226;</span>
                        <span>03-5703080</span>
                    </div>

                    <div>
                        <span onClick={() => showModalIframe('https://better-website-static.s3.eu-central-1.amazonaws.com/better-terms-of-use.html', 7250)}>תנאי שימוש</span>
                        <span className="dot-mobile">&#8226;</span>
                        <span onClick={() => showModalIframe('https://better-website-static.s3.eu-central-1.amazonaws.com/better-privacy.html', 2200)}>מדיניות פרטיות</span>
                    </div>
                </div>
            </div>
            <ReactModal
                className="modal"
                isOpen={!!modalSrc || !!iframeSrc}
                style={{ overlay: { background: 'rgba(0,0,0,0.4)', 'overflow-y': 'auto' } }}
                overlayElement={
                    (props, contentElement) => <div {...props} onClick={() => { setModalSrc(null); setIframeSrc(null) }}>{contentElement}</div>}
            >
                <div className="close-modal" onClick={() => { setModalSrc(null); setIframeSrc(null) }}>X</div>
                <div className="content">
                    {iframeSrc ? <iframe frameBorder="0" onmousewheel="" width="100%" height={iframeHeight} scrolling="no"
                        src={iframeSrc} /> : null}
                    {modalSrc ? <img src={modalSrc} /> : null}
                </div>
            </ReactModal>
        </div>
    );
}

export default Footer;
