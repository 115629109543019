import React from "react";

const Step = ({ number, title, text }) =>  {

  return (
    <div className="steps-wrapper">
      <div className="numbers">{number}</div>
      <h3>{title}</h3>
      <p className="small-text">
        {text}
      </p>
    </div>
  );
}

export default Step;
