import React from 'react';
import './LogosStyles.scss';

const Logo = ({ imgUrl, imgTitle }) => {
    return (
        <div className="logo">
            <img src={imgUrl} alt={imgTitle} />
        </div>
    );
};

export default Logo;
