import React, { useEffect, useRef, useState } from 'react';
import "../../App.scss";
import "./Home.scss";
import { isMobile } from 'react-device-detect';
// import CalendarSection from "../../components/library/CalendarSection";
import Calendar from "../../components/library/calendar";
import { Link, useParams } from "react-router-dom";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Mixpanel } from '../../mixpanel';
import Counter from "../../components/library/Counter";
import BannerCalendarWrapper from '../../components/library/calendarWrapper/BannerCalendarWrapper';
import LogoCarousel from '../../components/library/logoCarousel/LogoCarousel';
import { generateABTesting, ABTestRenderer } from '../../abTesting';

const factionNamesDict = {
    "brothers-in-arms": 'חמ"ל אחים לנשק',
    "family": 'המילואים'
}
const featuresUnderABTesting = ['mainHomeImage'];

function Home() {

    const abTestingFeatures = generateABTesting(featuresUnderABTesting);

    const { companyId, userId, campaign, affiliateCampaign, webCampaign, refId, name, family } = useParams();
    const [company, setCompany] = useState();
    const [faction, setFaction] = useState();
    const [miluim, setMiluim] = useState();
    const femaleDay = company && (company.includes('יום האישה') || company.includes('נשים בפרונט'));

    const getSpecificText = () => {
        if (femaleDay) {
            if (company && company.includes('נשים בפרונט')) {
                return 'בשביל באות הכנס נשים ב - Front';
            }
            return 'בשבילכן'
        }
        if (company && company.includes("ועידת משאבי אנוש")) {
            return 'בשביל משתתפי ועידת משאבי אנוש';
        }
        if (company && !window.location.href.includes("/free2")) {
            return window.location.href.includes("/lecture") ? `בשביל משתתפי הרצאת ${company}` : `בשביל עובדי ${company}`
        }
        else if (faction) {
            return `בשביל אנשי ${faction}`
        }
        else if (miluim) {
            return `בשביל משפחות ${miluim}`
        }

        return 'בשבילכם';
    }

    const getCompany = async () => {
        if (!companyId) {
            Mixpanel.track('home pageview', { campaign, affiliateCampaign, webCampaign, refId, ...abTestingFeatures });
            return;
        }

        try {
            const result = await fetch("https://p.allbetter.co.il/api/public/employer?employerId=" + companyId);
            const json = await result.json();

            const company = json.shortName || json.name;
            setCompany(company);
            console.log("company", company);
            Mixpanel.track('home pageview', { company, campaign, refId, ...abTestingFeatures });

        } catch (err) {
            Mixpanel.track('home pageview', { campaign, refId, ...abTestingFeatures });
            console.log("invalid company id")
        }
    }
    // rest of the code
    useEffect(() => {
        getCompany();
    }, [companyId]);


    useEffect(() => {
        setFaction(getFactionName(name));
    }, [name]);

    useEffect(() => {
        setMiluim(getFactionName(family));
    }, [family]);

    const getFactionName = (name) => {
        if (!name) {
            return null;
        }

        return factionNamesDict[name];
    }

    const scheduleZoomClicked = () => {
        console.log("scheduleZoomClicked");
        Mixpanel.track('schedule Zoom clicked', { page: 'home', button: 'main', company, campaign, refId, ...abTestingFeatures });
        
    }

    const adressingTheUser = () => {
        if (miluim) {
            return `למשפחות ${miluim}`
        }
        if (femaleDay) {
            return 'לכן';
        }

        return 'לך';
    }

    const generateParagraph = () => {
        if (miluim) {
            return (
                <p className="paragraph">
                    <span><span className="bold-green">בעיות תזרים -</span> דחיית תשלומי משכנתא והבנת המצב והאפשרויות העומדות לרשותכם</span>
                    <br />
                    <br />
                    <span><span className="bold-green">הלוואות חירום -</span> איתור הלוואות דחופות בתנאים הטובים ביותר</span>
                    <br />
                    <br />
                    <span><span className="bold-green">טיפול במקרי אסון -</span> בדיקת כיסויים ביטוחיים של פוליסות הביטוח שלכם אל מול כל מקרה שקרה לכם</span>
                    <br />
                    <br />
                    <span><span className="bold-green">שמירה על הכסף -</span> לאור נפילת הבורסה, השתתפות בכל התלבטות הנוגעת לשמירה על הכסף שלכם </span>
                </p>
            );
        }
        return (
            <p className="paragraph">
                אחרי אלפי לקוחות מרוצים אנו בטוחים בכך שנביא לכם ערך משמעותי
                ולפיכך מוכנים להתחייב:
                <br />{" "}
                <span className="bold-green">
                    אם לא נצליח לחסוך לכם כסף, לא נגבה מכם כל תשלום.
                </span>
                <br />
                בלי התחייבות ובלי אותיות קטנות.
                <br />
                <span className="extra-bold">כי אנחנו בצד שלכם.</span>
            </p>
        );
    }

    const mainHomeImageDesktopOptions = {
        v1: <img className="background" src="/images/all/Home-Section1-Background.png" />,
        v2: <video className="gif-video" autoPlay loop muted controls={false} playsInline >
            <source src="https://better-website-static.s3.eu-central-1.amazonaws.com/talking-heads-high-res.mp4" type="video/mp4" />
        </video>
    }

    const mainHomeImageMobileOptions = {
        v1: <img className="background-mobile-only" src="/images/all/main-image-mobile.png" />,
        v2: <video className="gif-video-mobile-only" autoPlay loop muted controls={false} playsInline>
            <source src="https://better-website-static.s3.eu-central-1.amazonaws.com/talking-heads-high-res.mp4" type="video/mp4" />
        </video>
    }

    return (
        <>
            <div className="home-container">
                {/* section-presentation */}
                <div className="section-presentation">
                    <ABTestRenderer feature="mainHomeImage" components={mainHomeImageDesktopOptions} />
                    <div className="container row">
                        <div className="col-6">
                            <div className="right-side">
                                <h2 className="title">
                                    <span className="g1">מסדרים {adressingTheUser()} את התנאים</span>
                                    <br />
                                    <span className="g2"> הכי טובים </span> בשוק.
                                </h2>

                                <div className="sup-title">
                                    <p>
                                        למי יש ראש לטפל עכשיו במשכנתא, בביטוח, בפנסיה, בהשקעות או
                                        בהלוואות? <br />{" "}
                                        <img
                                            className="better-min"
                                            src="/images/all/Betterlogo-min.svg"
                                        />
                                        עושים את הכל {getSpecificText()}, ומטפלים {femaleDay ? "במקומכן" : "במקומכם"} בכל הדברים שלא בא {femaleDay ? "לכן " : "לכם "}
                                        להתעסק בהם.
                                    </p>
                                </div>

                                <AnchorLink onClick={scheduleZoomClicked} offset='88' className="header-button-reverse"
                                    href='#zoom-schedule'>תיאום היכרות בזום</AnchorLink>
                            </div>
                        </div>
                    </div>
                </div>

                {/* company logos */}
                <div className='carousel'>
                    <div className="carousel-title">הרצינו בחברות הבאות:</div>
                    <LogoCarousel mobile={isMobile} />
                </div>

                {/* section2 */}
                <Counter />

                <ABTestRenderer feature="mainHomeImage" components={mainHomeImageMobileOptions} />
                {/* section2 */}
                <div className="section-parts">
                    <img className="background" src="/images/all/Home-Section2-Background.png" />
                    <div className="how-does-it-work">
                        <div className="s2-text-holder container">
                            <h1>איך זה עובד?</h1>
                            <div className="sub-text">
                                <div className="part">
                                    <h2 className="lightble-gradiant one">1.</h2>
                                    <h3>מכירים אתכם לעומק</h3>
                                    <p>
                                        מתחילים בשיחת זום בת 30 דקות בה אנו מקבלים אישור לאסוף בשבילכם את כל מה שצריך
                                    </p>
                                </div>
                                <div className="part">
                                    <h2 className="lightble-gradiant">2.</h2>
                                    <h3>הולכים לעבוד בשבילכם</h3>
                                    <p>
                                        המערכת שלנו סורקת את כל התנאים הכי טובים בשוק, ומזהה הזדמנויות לחיסכון
                                    </p>
                                </div>
                                <div className="part">
                                    <h2 className="lightble-gradiant">3.</h2>
                                    <h3>חוסכים עבורכם</h3>
                                    <p>
                                        תאשרו לנו בוואטסאפ לעשות בשבילכם את העבודה ולכו לשתות קפה טוב
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-new">
                    <div className="space row container">
                        <div className="text-holder col-8">
                            <h1 className="title-3">
                                <span className="g1">חוסכים לכם</span>{" "}
                                <span className="g2">מאות אלפי שקלים</span>


                            </h1>
                            <p className="paragraph">
                                באמצעות השימוש בשירות שלנו תחסכו עמלות, ריביות ודמי ניהול,
                                <br />
                                ותוכלו להתפנות לדברים החשובים באמת בחיים.
                                <br />{" "}
                                <br />{" "}
                                <span className="extra-bold">אנחנו נהיה כאן תמיד כדי לשמור עליכם.</span>
                            </p>
                        </div>
                        <div className="left-side col-6">
                            <img src="/images/all/background-secion-3.png" />
                        </div>
                    </div>

                </div>
                {/* section3 */}
                <div className="section-save ">
                    <div className="chat-background">
                        <div className="space row container">
                            <div className="text-holder col-6">
                                <h1 className="title-3">מתחייבים לחיסכון - ועומדים בכך</h1>
                                {generateParagraph()}
                            </div>
                            <div className="left-side clo-6">
                                <img src="/images/all/Home-section3-chat.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BannerCalendarWrapper
                Calendar={Calendar}
                isFromHomePage={true}
                image="/images/all/Home-Calendar.png"
                companyId={companyId}
                campaign={campaign}
                affiliateCampaign={affiliateCampaign}
                webCampaign={webCampaign}
                refId={refId}
                userId={userId}
                styles={{
                    height: isMobile ? '80vh' : '600px',
                    width: isMobile ? '100%' : '500px',
                }}
            />
        </>
    );
}

export default Home;
