import React from "react";
import "../../App.scss";
import "./SecurityAndPrivacy.scss";
// import CalendarSection from "../../components/library/CalendarSection";
import Calendar from "../../components/library/calendar";
import { Mixpanel } from '../../mixpanel';
import BannerCalendarWrapper from "../../components/library/calendarWrapper/BannerCalendarWrapper";

function SecurityAndPrivacy() {
  Mixpanel.track('security-and-privacy pageview');
  return (
    <>
      {/* section-Security and privacy*/}
      <div className="security-and-privacy">
        {/*container,row custom container for perfect aline*/}
        <div className="container row">
          {/* right side */}
          <div className="right-side col-5">
            <div className="company-logo">
              <div className="company-logo-1">
                <img src="/images/all/aws.png" />
              </div>
              <div className="company-logo-2">
                {" "}
                <img src="/images/all/Company-logo-2.png" />
              </div>
              <div className="company-logo-3">
                {" "}
                <img src="/images/all/Company-logo-5.png" />
              </div>
              <div className="company-logo-4">
                {" "}
                <img src="/images/all/Company-logo-4.png" />
              </div>
            </div>

            <div className="security-icon">
              <div>
                {" "}
                <img src="/images/all/Security-icone-4.svg" />
              </div>
              <div>
                {" "}
                <img src="/images/all/Security-icone-3.svg" />
              </div>
              <div>
                <img src="/images/all/Security-icone-2.svg" />
              </div>
              <div>
                {" "}
                <img src="/images/all/Security-icone-1.svg" />
              </div>
            </div>
          </div>
          {/* left side */}
          <div className="left-side col-6">
            <h2 className="title">אבטחה ופרטיות</h2>
            <p className="sup-title">
              {" "}
              <img className="better-min" src="/images/all/Betterlogo-min.svg" />
              מעניקה שירות אישי וקשוב במיוחד ללקוחותיה, וגאה להיות מפוקחת על ידי
              4 רגולטורים פיננסים: בנק ישראל, רשות שוק ההון, ביטוח וחיסכון,
              משרד המשפטים ומס ההכנסה.
            </p>
            {/* mobile logo */}
            <div className="company-logo-mobile">
              <div className="company-logo-mobile-1">
                <img src="/images/all/Company-logo-mobile-1.png" />
              </div>
              <div className="company-logo-mobile-2">
                {" "}
                <img src="/images/all/Company-logo-mobile-2.png" />
              </div>
              <div className="company-logo-mobile-3">
                {" "}
                <img src="/images/all/Company-logo-mobile-5.png" />
              </div>
              <div className="company-logo-mobile-4">
                {" "}
                <img src="/images/all/Company-logo-mobile-4.png" />
              </div>
            </div>
            <p className="sup-title">
              לפי הוראותיהם, כל המידע הפיננסי מוצפן ומופרד באופן הרמטי ממערכות
              עובדי החברה, כך שרק לכם ולמערכת הטכנולוגית יש גישה אליו. אף אחד
              מאיתנו לא יכול לגשת למידע בלי אישורכם!
            </p>
            <div className="security-icon-mobile">
                {" "}
                <img src="/images/all/Security-icone-4.svg" />
                {" "}
                <img src="/images/all/Security-icone-3.svg" />
                {" "}
                <img src="/images/all/Security-icone-2.svg" />
                {" "}
                <img src="/images/all/Security-icone-1.svg" />
            </div>
            {/*<p className="sup-title">*/}
            {/*  <img className="better-min" src="/images/all/Betterlogo-min.svg" />*/}
            {/*  מפעילה אמצעי אבטחת מידע מתקדמים אשר בשימוש של החברות הפיננסיות*/}
            {/*  הגדולות בישראל ובעולם. תקני אבטחת המידע שלנו הינם בהתאם ל.......*/}
            {/*  ובתקן TLS ......*/}
            {/*</p>*/}
          </div>
        </div>
      </div>
      <BannerCalendarWrapper Calendar={Calendar} image="/images/all/Security-Calendar.png" />
    </>
  );
}
export default SecurityAndPrivacy;
