import React from "react";
import "../../App.scss";
import "./Companies.scss";
// import CalendarSection from "../../components/library/CalendarSection";
import Calendar from "../../components/library/calendar";
import { Link } from "react-router-dom";
import jsonData from '../qAndA/q&a.json';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Mixpanel } from '../../mixpanel';
import BannerCalendarWrapper from "../../components/library/calendarWrapper/BannerCalendarWrapper";

function Companies() {
  Mixpanel.track('companies pageview');
  return (
    <>
      <div className="companies-container">
        {/* section-presentation */}
        <div className="section-presentation">
          <div className="container row">
            <div className="col-6">
              <div className="right-side">
                <h2 className="title">
                  ככה עוזרים
                  <span className="g1"> לעובדי הייטק</span>
                  <br className="web-break" /> לחסוך
                  <span className="g2"> &#8362;200,000 </span> לפחות.
                </h2>

                <div className="sup-title">
                  <p>
                    אנחנו עוזרים לכל עובד לחסוך 200,000 ש"ח בכל הנושאים שהוא אף פעם לא מגיע לטפל בהם. כי למי יש ראש לטפל עכשיו במשכנתא, בביטוח, בפנסיה, בהשקעות או בהלוואות?
                  </p>
                  <p>
                    <img
                      className="better-min"
                      src="/images/all/Betterlogo-min.svg"
                    />
                    עושים את הכל בשביל העובדים שלכם. אנחנו מטפלים במקומם בכל הדברים שלא בא להם להתעסק בהם, ומפנים את הזמן לדברים החשובים בחיים.
                  </p>
                </div>

                <AnchorLink offset='88' className="header-button" href='#zoom-schedule'>תיאום היכרות בזום</AnchorLink>
              </div>
            </div>
          </div>
        </div>

        {/* section2 */}
        <div className="section-parts">
          <div className="gradiant-background">
            <img
              src="/images/all/Companies-Section1-mobile.png"
              className="flow-image"
            />
            <div className="s2-text-holder container">
              <h1>איך זה עובד?</h1>
              <div className="sub-text">
                <div className="part">
                  <h2 className="lightble-gradiant">1.</h2>
                  <h3>מכירים את העובדים לעומק</h3>
                  <p>מתחילים בשיחת זום בת 30 דקות בה אנו מקבלים אישור לאסוף בשבילכם את כל מה שצריך</p>
                </div>
                <div className="part">
                  <h2 className="lightble-gradiant">2.</h2>
                  <h3>הולכים לעבוד עבורם ובשבילכם</h3>
                  <p>
                    המערכת שלנו סורקת את כל התנאים הכי טובים בשוק, ומזהה הזדמנויות לחיסכון
                  </p>
                </div>
                <div className="part">
                  <h2 className="lightble-gradiant">3.</h2>
                  <h3>חוסכים עבורם</h3>
                  <p>
                    אנחנו מקבלים מהעובדים אישור דיגיטלי וחוסכים להם עשרות אלפי שקלים בלי שיידרשו לעשות דבר
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* section3 */}
        <div className="section-save ">
          <div className="chat-background">
            <div className="space row container">
              <div className="text-holder col-6">
                <h1 className="title-3">שירות אנושי, טכנולוגיה מאפשרת</h1>
                <p>
                  <img
                    className="better-min"
                    src="/images/all/Betterlogo-min-white.svg"
                  />
                  הוקמה על ידי מומחי פיננסים וטכנולוגיה, כל אחד עם יותר מ-15
                  שנות ניסיון.
                </p>
                <p>
                  פיתחנו טכנולוגיה ייחודית שעושה את כל העבודה עבור הלקוחות שלנו,
                  כך שהם לא צריכים לעשות כלום: אנחנו משיגים את המידע באופן
                  אוטומטי וסורקים בכל רגע נתון איך אפשר לחסוך כסף בכל מקום -
                  במס, בביטוח, בפנסיה, במשכנתא, בהשקעות, ובגדול - בכל תחום שקשור
                  לפיננסים.
                </p>
                <p>
                  ברגע שאנו מזהים פוטנציאל לחיסכון אנחנו מבקשים מהעובד אישור לבצע את הפעולה ולחתום דרך הטלפון, וכל זה בשקיפות ובזמינות מלאה אליהם בוואטסאפ ובנייד בכל יום בין 9:00-21:00 <br/>במספר 03-5703080
                </p>
              </div>
              <div className="left-side clo-6">
                <img src="/images/all/Companies-section3-chat.png" />
              </div>
            </div>
          </div>
        </div>
        {/* section 4-commit */}
        <div className="section-commit">
          <div className="container row">
            <div className="right-side col-6">
              <h1 className="title">
                <span className="g1"> חיסכון משמעותי</span>
                <br /> בראש שקט
              </h1>
              <p className="sup-title">
                חסכנו עד היום סכום ממוצע של 200,000 ש"ח ללקוח בעמלות ובדמי
                ניהול, ולכן אנחנו מאמינים שנוכל למצוא כאלה חסכונות גם לעובדים
                שלכם.
              </p>
            </div>
            <div className="left-side col-6">
              <img
                className="web"
                src="/images/all/Companies-Section4-image.png"
                alt="circular"
              />
            </div>
          </div>
        </div>
        {/* section-Security and privacy*/}
        <div className="security-and-privacy">
          {/*container,row custom container for perfect aline*/}
          <div className="container row">
            {/* right side */}
            <div className="right-side col-5">
              <div className="company-logo">
                <div className="company-logo-1">
                  <img src="/images/all/aws.png" />
                </div>
                <div className="company-logo-2">
                  {" "}
                  <img src="/images/all/Company-logo-2.png" />
                </div>
                <div className="company-logo-3">
                  {" "}
                  <img src="/images/all/Company-logo-5.png" />
                </div>
                <div className="company-logo-4">
                  {" "}
                  <img src="/images/all/Company-logo-4.png" />
                </div>
              </div>

              <div className="security-icon">
                <div>
                  {" "}
                  <img src="/images/all/Security-icone-4.svg" />
                </div>
                <div>
                  {" "}
                  <img src="/images/all/Security-icone-3.svg" />
                </div>
                <div>
                  <img src="/images/all/Security-icone-2.svg" />
                </div>
                <div>
                  {" "}
                  <img src="/images/all/Security-icone-1.svg" />
                </div>
              </div>
            </div>
            {/* left side */}
            <div className="left-side col-6">
              <h2 className="title">אבטחה ופרטיות</h2>
              <p className="sup-title">
                {" "}
                <img className="better-min" src="/images/all/Betterlogo-min.svg" />
                מעניקה שירות אישי וקשוב במיוחד ללקוחותיה, וגאה להיות מפוקחת על
                ידי 4 רגולטורים פיננסים: בנק ישראל, רשות שוק ההון, ביטוח
                וחיסכון, משרד המשפטים ומס ההכנסה.
              </p>
              {/* mobile logo */}
              <div className="company-logo-mobile">
                <div className="company-logo-mobile-1">
                  <img src="/images/all/Company-logo-mobile-1.png" />
                </div>
                <div className="company-logo-mobile-2">
                  {" "}
                  <img src="/images/all/Company-logo-mobile-2.png" />
                </div>
                <div className="company-logo-mobile-3">
                  {" "}
                  <img src="/images/all/Company-logo-mobile-5.png" />
                </div>
                <div className="company-logo-mobile-4">
                  {" "}
                  <img src="/images/all/Company-logo-mobile-4.png" />
                </div>
              </div>
              <p className="sup-title">
                לפי הוראותיהם, כל המידע הפיננסי מוצפן ומופרד באופן הרמטי ממערכות
                עובדי החברה, כך שרק לכם ולמערכת הטכנולוגית יש גישה אליו. אף אחד
                מאיתנו לא יכול לגשת למידע בלי אישורכם!
              </p>
              <div className="security-icon-mobile">
                <div>
                  {" "}
                  <img src="/images/all/Security-icone-4.svg" />
                </div>
                <div>
                  {" "}
                  <img src="/images/all/Security-icone-3.svg" />
                </div>
                <div>
                  <img src="/images/all/Security-icone-2.svg" />
                </div>
                <div>
                  {" "}
                  <img src="/images/all/Security-icone-1.svg" />
                </div>
              </div>
              <p className="sup-title">
                <img className="better-min" src="/images/all/Betterlogo-min.svg" />
                מפעילה אמצעי אבטחת מידע מתקדמים אשר בשימוש של החברות הפיננסיות המובילות בעולם
              </p>
            </div>
          </div>
        </div>
        {/*the blue container 100% width */}
        <div className="section6-save ">
          {/*container,row custom container for perfect aline*/}
          {/* <div className="colored-box-Background"> */}
          <div className="container row">
            {/*right side container holder */}
            <div className="right-side col-6">
              <h2 className="title ">מתחייבים לחיסכון - ועומדים בכך</h2>

              <p className="paragraph">
                אחרי אלפי לקוחות מרוצים אנו בטוחים בכך שנביא לכם ערך משמעותי
                ולפיכך מוכנים להתחייב:
              </p>
              <p className=" bold-green">
                אם לא נצליח לחסוך לכם כסף, לא נגבה מכם כל תשלום.
              </p>
              <p className="paragraph">
                בלי התחייבות ובלי אותיות קטנות.
                <br/>
                כי אנחנו בצד שלכם.
              </p>
            </div>
            {/*left side container holder */}
            <div className="left-side col-6">
              <img
                src="/images/all/Companies-Section6-image.png"
                alt="circular"
                className="web-image"
              />
              <img
                src="/images/all/Companies-Section6-image-mobile.png"
                alt="circular"
                className="mobile-image"
              />
            </div>
          </div>
          {/* </div> */}
        </div>
        {/* Q&A */}
        {/*<div className="section-q-and-a container">*/}
        {/*  <h1 className="title">שאלות ותשובות</h1>*/}
        {/*  <div className="accordion">*/}
        {/*    {[jsonData[0], jsonData[1], jsonData[3], jsonData[4]].map((item, i) => <div className="c">*/}
        {/*          <input type="checkbox" id={'faq-' + i}/>*/}
        {/*          <h1>*/}
        {/*            <label htmlFor={'faq-' + i}>{item.q}</label>*/}
        {/*          </h1>*/}
        {/*          <div className="p">*/}
        {/*            <p>{item.a}</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <BannerCalendarWrapper Calendar={Calendar} image="/images/all/Howitwork-Calendar.png" company-page={true} />
    </>
  );
}

export default Companies;
