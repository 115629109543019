import React, { useRef, useState } from 'react';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import NoConvenientTimePopUp from '../NoConvenientTimePopUp';

function BannerCalendarWrapper({ Calendar, onceShownAlwaysShow = false, externalRef, title, subtitle, ...props }) {
    const internalRef = useRef(null);
    const ref = externalRef || internalRef;
    const isCalendarInView = useIntersectionObserver(ref);
    const [hasBeenInView, setHasBeenInView] = useState(false);

    if (isCalendarInView && !hasBeenInView) {
        setHasBeenInView(true);
    }

    const showBanner = onceShownAlwaysShow ? hasBeenInView : isCalendarInView;

    return (
        <>
            <div ref={!externalRef ? ref : undefined}>
                <Calendar {...props} />
            </div>
            {showBanner && (
                <NoConvenientTimePopUp
                    {...(title && { title })}
                    {...(subtitle && { subtitle })}
                />
            )}
        </>
    );
}

export default BannerCalendarWrapper;
