import React, { useState, useRef } from 'react';
import './ThankYouPage.scss';
import { isMobile } from 'react-device-detect';
import LogoGrid from "../../components/library/LogoGrid";
import LogoCarousel from "../../components/library/logoCarousel/LogoCarousel";
import FAQ from "../../components/library/fAQ/FAQ-new";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import ReactModal from 'react-modal';

const ThankYouPage = () => {
    const [modalSrc, setModalSrc] = useState();
    const [iframeSrc, setIframeSrc] = useState();
    const [iframeHeight, setIframeHeight] = useState();
    const showModalIframe = (src, iframeHeight) => {
        setIframeSrc(src);
        setIframeHeight(iframeHeight)
    }

    const videoRef = useRef(null);

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    };

    return (
        <div className="thank-you-page">

            {/* Section 1 */}
            <div className="welcome-container">
                <div className="text-container">
                    <h1>שמחים שהצטרפת אלינו,<br />מעכשיו נתחיל לחסוך.</h1>
                    <p>
                        יש לנו תחושה טובה שזו התחלה של <b>משהו גדול</b>.<br />
                        הזמנה לפגישה תשלח אליך באימייל וב-Whatsapp.<br />
                        הפגישה תתקיים ב-Zoom ותארך כחצי שעה, מומלץ<br />
                        להצטרף דרך המחשב.
                    </p>
                </div>
                <div className="icon-container">
                    <img src='/images/all/circle-with-checkmark.svg' alt='circle with checkmark' />
                </div>
            </div>


            {/* Section 2 */}
            <div className="process-steps">
                <div className="title">
                    <span className="highlight">תהליך פשוט, </span>
                    {isMobile ? <br /> : null}
                    שתדעו למה לצפות
                </div>
                <p className="description">
                    באמצעות השימוש בשירות שלנו תחסכו עלויות, ריביות וזמני ניהול, <br />ותוכלו להתפנות לדברים החשובים באמת בחיים.
                </p>
                <div className="steps-container">
                    <div className="step">
                        <div className="step-number gradient-text">1</div>
                        <div className="step-title">מכירים אתכם לעומק</div>
                        <div className="step-description">
                            מתחילים בשיחת זום בת 30 דקות בה אנו מקבלים אישור לאסוף בשבילכם את כל מה שצריך
                        </div>
                    </div>
                    <div className='arrow'>{arrow}</div>
                    <div className="step">
                        <div className="step-number gradient-text">2</div>
                        <div className="step-title">הולכים לעבוד בשבילכם</div>
                        <div className="step-description">
                            המערכת שלנו סורקת את כל הנתונים הכי טובים בשוק, ומזהה הזדמנויות לחיסכון
                        </div>
                    </div>
                    <div className='arrow'>{arrow}</div>
                    <div className="step">
                        <div className="step-number gradient-text">3</div>
                        <div className="step-title">חוסכים עבורכם</div>
                        <div className="step-description">
                            תאשרו לנו בוואטסאפ לעשות בשבילכם את העבודה ולכו לשתות קפה טוב
                        </div>
                    </div>
                </div>
            </div>


            {/* Section 3 */}
            <div className="testemonial-container">
                <video ref={videoRef} poster='/images/all/Screenshot-tal2.png' onClick={handlePlayPause}>
                    <source src="https://better-website-static.s3.eu-central-1.amazonaws.com/Tal04+-+Onboarding.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="title-container">
                    <span className="title-text-light">הלקוחות שלנו עובדים בחברות האלו </span>
                    {!isMobile ? <br /> : null}
                    <span className="title-text-bold">וחתכנו להם בעמלות מיותרות</span>
                </div>
            </div>

            {isMobile ? <LogoCarousel mobile={true} /> :
                <div className="companies-container">
                    <LogoGrid numRows={2} numImagesPerRow={6} imageDirectory="/images/all/companies-logos/landing-page-logos" />
                </div>
            }
            <br />


            {/* Section 4 */}
            <div className="security-compliance-container">
                <div className="security-compliance">
                    <div className="lock-container">
                        <img src="/images/all/lock-image-black.svg" alt="lock" className="lock-icon" />
                    </div>
                    <div className='text-and-stamps'>
                        <span className="title">אבטחה מקסימלית בהתאם לרגולציה</span>
                        <div className="images-container">
                            <img src="/images/all/meyupeKoah.svg" alt="meyupeKoah" className="icon" />
                            {isMobile ? <span>מרשם מיופי כוח בתמורה של  בנק ישראל - מיופה כוח בתמורה</span> : null}
                            <img src="/images/all/misradHamishpatim.svg" alt="misradHamishpatim" className="icon" />
                            {isMobile ? <span>משרד המשפטים - מאגר מידע רשום</span> : null}
                            <img src="/images/all/yoazeyMas.png" alt="yoazeyMas" className="icon" />
                            {isMobile ? <span>מועצת יועצי המס - רישיון יועץ מס</span> : null}
                            <img src="/images/all/shukHaHon.svg" alt="shukHaHon" className="icon" />
                            {isMobile ? <span className='slightly-up'>רישיון שוק ההון, ביטוח וחיסכון - בעל רישיון פנסיוני</span> : null}
                        </div>
                    </div>
                </div>
            </div>


            {/* Section 5 */}
            <div className="q-and-a-container">
                <div className="faq-container">
                    <div className="q-and-a-container-title">נשמח לענות{<br />} על כל שאלה</div>
                    <div className="q-and-a-container-faq">
                        <FAQ />
                    </div>
                </div>
                <div className="q-and-a-container-button-container">
                    <button className="q-and-a-container-button-container-button" style={{ fontWeight: '600' }} onClick={() => window.location.href = '/Contact'}>
                        שאלה? בקשה? צרו איתנו קשר
                    </button>
                </div>
            </div>


            {/* Footer */}
            <div className="footer">
                <div className="footer-body lp-container">
                    <div className="footer-body-license">
                        <span>רשיונות ומפקחים פיננסיים</span>
                        <ul>
                            <li>רישיון בנק ישראל - מיופה כוח בתמורה</li>
                            <li>רישיון שוק ההון, ביטחון וחיסכון - בעל רישיון פנסיוני</li>
                            {/* <li>רישיון הרשות לניירות ערך - נותן שירותי מידע פיננסי</li> */}
                            <li>רישיון מס הכנסה - חברת ייעוץ מס</li>
                            <li>משרד המשפטים - מאגר מידע רשום</li>
                        </ul>
                    </div>
                    <div className={isMobile ? "horizontal-line" : "vertical-line"} style={isMobile ? { order: 2 } : {}}></div>
                    <div className="footer-body-better">
                        <img src="/images/all/new-landing-page-better-logo-white.svg" alt="Better" />
                        <p>תנו לטכנולוגיה לחתוך לכם את העמלות</p>
                    </div>
                    <div className={isMobile ? "horizontal-line" : "vertical-line"} style={isMobile ? { order: 4 } : {}}></div>
                    <div className="footer-body-sm">
                        <div className="footer-body-sm-icons">
                            <a href="https://www.instagram.com/allbetter_finance" target="_blank" rel="noreferrer">
                                <InstagramIcon sx={{ color: "rgba(90, 227, 207, 1)" }} />
                            </a>
                            <a href="https://www.linkedin.com/company/betterpfm" target="_blank" rel="noreferrer">
                                <LinkedInIcon sx={{ color: "rgba(90, 227, 207, 1)" }} />
                            </a>
                            <a href="https://twitter.com/BetterPFM" target="_blank" rel="noreferrer">
                                <TwitterIcon sx={{ color: "rgba(90, 227, 207, 1)" }} />
                            </a>
                            <a href="https://www.facebook.com/people/Better/100086255880240/" target="_blank" rel="noreferrer">
                                <FacebookIcon sx={{ color: "rgba(90, 227, 207, 1)" }} />
                            </a>
                        </div>
                        <div className="footer-body-sm-text">
                            <p>דרך בגין 114, תל אביב יפו &nbsp;&#8226;&nbsp; <a href="mailto:support@allbetter.co.il">support@allbetter.co.il</a> </p>
                            <p>
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => showModalIframe('https://better-website-static.s3.eu-central-1.amazonaws.com/better-terms-of-use.html', 7250)}
                                >תנאי שימוש
                                </span>
                                &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => showModalIframe('https://better-website-static.s3.eu-central-1.amazonaws.com/better-privacy.html', 2200)}
                                >מדיניות פרטיות
                                </span>
                            </p>
                            <ReactModal
                                className="modal"
                                isOpen={!!modalSrc || !!iframeSrc}
                                style={{ overlay: { background: 'rgba(0,0,0,0.4)', 'overflow-y': 'auto' } }}
                                overlayElement={
                                    (props, contentElement) => <div {...props} onClick={() => { setModalSrc(null); setIframeSrc(null) }}>{contentElement}</div>}
                            >
                                <div className="close-modal" onClick={() => { setModalSrc(null); setIframeSrc(null) }}>X</div>
                                <div className="content">
                                    {iframeSrc ? <iframe frameBorder="0" onmousewheel="" width="100%" height={iframeHeight} scrolling="no"
                                        src={iframeSrc} /> : null}
                                    {modalSrc ? <img src={modalSrc} alt="" /> : null}
                                </div>
                            </ReactModal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThankYouPage;


const arrow = <svg width="30" height="54" viewBox="0 0 30 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29 52.5764L1 26.9355L29 1.29468" stroke="#3566F6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</svg>
