import React from 'react';
import { useState } from 'react';
import "./NoConvenientTimePopUp.scss";
import { Mixpanel } from '../../mixpanel';
import useCampaign from "../../components/library/useCampaign";
import CloseIcon from '@mui/icons-material/Close';
import { getABTestingObject } from "../../abTesting";

function NoConvenientTimePopUp({ title, subtitle }) {
    const [bannerVisible, setBannerVisible] = useState(true);
    const [bannerOpened, setBannerOpened] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const {
        handleEvent,
        campaignType,
        campaignName
    } = useCampaign({});


    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormSubmitted(true);

        Mixpanel.track('no conveninet time pop message sent', {
            contactType: "noconvenienttime",
            email,
            phoneNumber,
            fullName,
            title: title ? title : "לא מצאתם מועד מתאים?",
            date: new Date(),
            campaignName,
            campaignType,
            location: window.location.href,
            ...getABTestingObject()
        });

        const body = {
            contactType: "noconvenienttime",
            email,
            phoneNumber,
            fullName,
            title: title ? title : "לא מצאתם מועד מתאים?",
            date: new Date(),
            location: window.location.href,
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        };
    
        await fetch('https://hook.eu1.make.com/r70mg9xqnzaneiwj6rndwv1d14iodpgo', requestOptions);

        const host = window.location.href.includes("localhost") ? 'http://localhost:4000' : 'https://p.allbetter.co.il';
        const response = await fetch(host + '/api/public/website-email', requestOptions);

        handleEvent();
    };

    const handleArrowClick = () => {
        setBannerOpened(!bannerOpened);
    }

    const handleBannerClose = (e) => {
        e.stopPropagation();
        setBannerVisible(false);
    }

    return (
        <>
            {bannerVisible && <div className={`banner ${bannerOpened ? 'open' : 'close'}`}>
                <div className="close-button" onClick={handleBannerClose}><CloseIcon /></div>
                <div className="headings" onClick={handleArrowClick}>
                    <img className="top-right-img" alt="" src="/images/all/icon-on-popup-banner.svg" />
                    <div className="titles">
                        <h1 className="main-heading">{title ? title : "לא מצאתם מועד מתאים?"}</h1>
                        <p className="sub-heading">{subtitle ? subtitle : "הירשמו ונתאם אתכם פגישה בהקדם"}</p>
                    </div>
                    <img className="arrow-img" alt="" src={`/images/all/arrow-${bannerOpened ? 'down' : 'up'}.png`} />
                </div>
                {bannerOpened &&
                    <form class="contact-form" onSubmit={handleSubmit}>
                        <div className="form-fields">
                            <div className="row">
                                <div className="col-0">
                                    <h6>איך קוראים לך?</h6>
                                    <input
                                        type="text"
                                        id="input-name"
                                        placeholder=""
                                        onChange={e => setFullName(e.target.value)}
                                        required
                                        minLength={2}
                                        disabled={formSubmitted}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <h6>טלפון</h6>
                                    <input
                                        type="tel"
                                        id="input-phone-number"
                                        placeholder=""
                                        onChange={e => setPhoneNumber(e.target.value)}
                                        required
                                        pattern='^(05)-?\d{8}$'
                                        disabled={formSubmitted}
                                    />
                                </div>
                                <div className="col-8">
                                    <h6>מייל</h6>
                                    <input
                                        type="email"
                                        id="input-email"
                                        placeholder=""
                                        onChange={e => setEmail(e.target.value)}
                                        required
                                        pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                                        disabled={formSubmitted}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="button-wrapper">
                            <button
                                type="submit"
                                id="input-submit"
                                disabled={formSubmitted}
                            >
                                {formSubmitted && <img src="/images/all/check-mark.svg" alt="Form Submitted" />}
                                {formSubmitted ? "נהיה בקשר בהקדם" : "שליחה"}
                            </button>
                        </div>
                    </form>}
            </div>}
        </>
    )
}

export default NoConvenientTimePopUp;
