import { Link } from "@material-ui/core";
import React, { useEffect, useRef, useState } from 'react';
import "../../App.scss";
import "./Contact.scss";
import Calendar from "../../components/library/calendar";
import { useHistory, useParams } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Mixpanel } from '../../mixpanel';
import useCampaign from "../../components/library/useCampaign";
import BannerCalendarWrapper from "../../components/library/calendarWrapper/BannerCalendarWrapper";
import { getABTestingObject } from "../../abTesting";

function Contact() {
  Mixpanel.track('contact-us pageview');

  const history = useHistory();
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [requiredDate, setRequiredDate] = useState('');
  const [requiredTime, setRequiredTime] = useState('');

  const [message, setMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const {
    handleEvent,
    campaignType,
    campaignName
  } = useCampaign({});


  const texts = [{
    type: 'contact',
    title: "יש לכם שאלות? צריכים עוד קצת מידע? הגעתם למקום הנכון!",
    subTitle: <>תוכלו לתאם איתנו{" "}
      <AnchorLink offset='88' href='#zoom-schedule'>שיחה קצרה בזום</AnchorLink>
      {" "}
      <br className="mobile-break" />
      או להשאיר את הפרטים כאן</>,
    buttonText: "שליחה",
    fullName: true,
    email: true,
    phoneNumber: true,
    message: true,
  }, {
    type: "noconvenienttime",
    title: "מתי תרצו לתאם",
    subTitle: "",
    buttonText: "תאמו לי פגישה",
    fullName: true,
    email: true,
    phoneNumber: true,
    requiredDateTime: true,
  }, {
    type: "meetingtoday",
    title: "ניצור קשר במידה שיתפנה לנו המועד",
    subTitle: "",
    buttonText: "שליחה",
    fullName: true,
    email: true,
    phoneNumber: true
  }, {
    type: "moreinfo",
    title: "יש לכם שאלות? רוצים עוד קצת מידע? הגעתם למקום הנכון",
    subTitle: "",
    buttonText: "שליחה",
    fullName: true,
    email: true,
    phoneNumber: true
  }];

  const getContactType = () => {
    const splits = window.location.pathname.split("/");
    return splits[splits.length - 1].toLowerCase();
  }

  const getTexts = () => {
    const contactType = getContactType();

    return texts.find(item => item.type === contactType);
  }

  const handleChange = (event, func) => {
    func(event.target.value);
    setShowErrorMessage(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (getTexts().email && !email) {
      setShowErrorMessage(true);
      return;
    } else if (getTexts().phoneNumber && !phoneNumber) {
      setShowErrorMessage(true);
      return;
    } else if (getTexts().fullName && !fullName) {
      setShowErrorMessage(true);
      return;
    } else if (getTexts().message && !message) {
      setShowErrorMessage(true);
      return;
    } else if (getTexts().requiredDateTime && (!requiredTime || !requiredDate)) {
      setShowErrorMessage(true);
      return;
    }

    Mixpanel.track('website message sent', {
      contactType: getContactType(),
      email,
      phoneNumber,
      fullName,
      message,
      requiredTime,
      requiredDate,
      title: getTexts().title,
      date: new Date(),
      campaignName,
      campaignType,
      ...getABTestingObject()
    });

    const body = {
      contactType: getContactType(),
      email,
      phoneNumber,
      fullName,
      message,
      requiredTime,
      requiredDate,
      title: getTexts().title,
      date: new Date()
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    };

    await fetch('https://hook.eu1.make.com/r70mg9xqnzaneiwj6rndwv1d14iodpgo', requestOptions);

    const host = window.location.href.includes("localhost") ? 'http://localhost:4000' : 'https://p.allbetter.co.il';
    const response = await fetch(host + '/api/public/website-email', requestOptions);

    handleEvent();
  };

  return (
    <>
      <div className="contact">
        <div className="contact-form container">
          <div className="right-side ">
            <h1 className="title">{getTexts().title}</h1>
            <p className="sup-title">{getTexts().subTitle}
            </p>
            <form class="cf" onSubmit={handleSubmit}>
              {getTexts().fullName && <div class="cf">
                <h6>איך קוראים לכם?</h6>
                <input type="text" id="input-name" placeholder="" onChange={event => handleChange(event, setFullName)} />
              </div>}
              <div class="cf row">
                {getTexts().phoneNumber && <div className="col-4">
                  <h6>טלפון</h6>
                  <input type="tel" id="input-phone-number" placeholder="" onChange={event => handleChange(event, setPhoneNumber)} />
                </div>}
                {getTexts().email && <div className="col-8">
                  <h6>מייל</h6>
                  <input type="email" id="input-email" placeholder="" onChange={event => handleChange(event, setEmail)} />
                </div>}
              </div>
              {getTexts().message && <div class="cf">
                <h6>רוצים לכתוב לנו משהו?</h6>
                <textarea
                  name="message"
                  type="text"
                  id="input-message"
                  placeholder=""
                  onChange={event => handleChange(event, setMessage)}
                />
              </div>
              }
              {getTexts().requiredDateTime && <div class="cf row">
                <div className="col-4">
                  <h6>תאריך לפגישה</h6>
                  <input type="tel" id="input-date" placeholder="" onChange={event => handleChange(event, setRequiredDate)} />
                </div>
                <div className="col-8">
                  <h6>שעה מועדפת</h6>
                  <input type="tel" id="input-time" placeholder="" onChange={event => handleChange(event, setRequiredTime)} />
                </div>
              </div>}

              <div className="button-wrapper">
                {showErrorMessage ? <h4>יש למלא את כל השדות</h4> : null}
                <input type="submit" value={getTexts().buttonText} id="input-submit" />
              </div>
            </form>
          </div>
          <div className="left-side ">
            <img
              className="web"
              src="/images/all/Contact-image.png"
              alt="circular"
            />
          </div>
        </div>
      </div>
      <BannerCalendarWrapper Calendar={Calendar} image="/images/all/Security-Calendar.png" />
    </>
  );
}

export default Contact;
