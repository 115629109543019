import React from 'react';

const LogoGrid = ({ numRows, numImagesPerRow, imageDirectory, mobile }) => {
    const generateImageSrc = (row, index, fileType) => {
        const prefix = mobile ? 'mobile/' : '';
        return `${imageDirectory}/${prefix}${row}_${index + 1}.${fileType}`;
    };

    return (
        <div>
            {[...Array(numRows)].map((_, row) => (
                <div key={row + 1} className={`row${row + 1}`}>
                    {Array.from({ length: numImagesPerRow }, (_, index) => (
                        <img
                            key={index + 1}
                            src={generateImageSrc(row + 1, index, 'svg')}
                            alt={`Image ${row + 1}-${index + 1}`}
                            onError={(e) => {
                                e.target.src = generateImageSrc(row + 1, index, 'png');
                            }}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default LogoGrid;