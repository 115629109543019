import React from "react";

const LicenseItem = ({ image, text }) => {
  return (
    <div className="license-item">
      <div className="image-wrp">
        <img src={image} alt="" className="license-image" />
      </div>
      <p>{text}</p>
    </div>
  );
}

export default LicenseItem;
