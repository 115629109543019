import React from 'react';
import './LogosStyles.scss';
import Logo from './Logo';
import { LogoDetails, skipIndex } from './LogoDetails';

const LogoCarousel = ({ mobile }) => {
    const totalLogos = LogoDetails.length;
    const halfLogos = Math.floor(totalLogos / 2);

    const desktopContainerWidth = totalLogos * 200; // Each logo has a width of 200px
    const desktopStartPositionTrack1 = 0;
    const desktopEndPositionTrack1 = -1 * desktopContainerWidth;
    const desktopStartPositionTrack2 = desktopContainerWidth;
    const desktopEndPositionTrack2 = 0;

    const mobileContainerWidth = halfLogos * 200;
    const mobileStartPositionTrack1 = -1 * window.innerWidth;
    const mobileEndPositionTrack1 = mobileContainerWidth;
    const mobileStartPositionTrack2 = mobileContainerWidth;
    const mobileEndPositionTrack2 = 0 - window.innerWidth;

    const carouselTrackContainerStyle = {
        width: `${mobile ? mobileContainerWidth : desktopContainerWidth}px`,
        minWidth: `${mobile ? mobileContainerWidth : desktopContainerWidth}px`,
        '--desktop-start-position1': `${desktopStartPositionTrack1}px`,
        '--desktop-end-position1': `${desktopEndPositionTrack1}px`,
        '--desktop-start-position2': `${desktopStartPositionTrack2}px`,
        '--desktop-end-position2': `${desktopEndPositionTrack2}px`,
        '--mobile-start-position1': `${mobileStartPositionTrack1}px`,
        '--mobile-end-position1': `${mobileEndPositionTrack1}px`,
        '--mobile-start-position2': `${mobileStartPositionTrack2}px`,
        '--mobile-end-position2': `${mobileEndPositionTrack2}px`,
        '--desktop-animation-duration': '25s',
        '--mobile-animation-duration': '20s'
    };

    let mobileTrack1, mobileTrack2;
    if (skipIndex) {
        const logoDetailsWithoutSkipIndex = LogoDetails.filter((_, index) => index !== skipIndex);
        mobileTrack1 = logoDetailsWithoutSkipIndex.slice(0, halfLogos);
        mobileTrack2 = logoDetailsWithoutSkipIndex.slice(halfLogos);
    }
    else {
        mobileTrack1 = LogoDetails.slice(0, halfLogos);
        mobileTrack2 = LogoDetails.slice(halfLogos);
    }

    return (
        <>
            {mobile ? (
                <div className="carouselContainerMobile">
                    <div className="carouselTrack1Mobile" style={carouselTrackContainerStyle}>
                        {mobileTrack1.map((logo, index) => (
                            <Logo key={`logo1-${index}`} imgUrl={logo.imgUrl} imgTitle={logo.title} />
                        ))}
                    </div>
                    <div className="carouselTrack2Mobile" style={carouselTrackContainerStyle}>
                        {mobileTrack2.map((logo, index) => (
                            <Logo key={`logo2-${index}`} imgUrl={logo.imgUrl} imgTitle={logo.title} />
                        ))}
                    </div>
                </div>
            ) : (
                <div className="carouselContainer">
                    <div className="carouselTrack1" style={carouselTrackContainerStyle}>
                        {LogoDetails.map((logo, index) => (
                            <Logo key={`logo1-${index}`} imgUrl={logo.imgUrl} imgTitle={logo.title} />
                        ))}
                    </div>
                    <div className="carouselTrack2" style={carouselTrackContainerStyle}>
                        {LogoDetails.map((logo, index) => (
                            <Logo key={`logo2-${index}`} imgUrl={logo.imgUrl} imgTitle={logo.title} />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default LogoCarousel;
