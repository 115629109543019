import React from 'react';
import jsonData from './../../../data/q&a.json';
import './FAQ.scss';

const FAQ = () => {
  return (
    <div className="faq-container">
        {/* qanda*/}
        <div className="question">
          <h1 className="title">נשמח לענות על כל שאלה</h1>
          {/* accordion */}
          <div className="accordion">
            {jsonData.map((item,i)=><div key={'faq-'+i} className="c">
              <input type="checkbox" id={'faq-'+i} />
              <h1>
                <label htmlFor={'faq-'+i}>{item.q}</label>
              </h1>
              <div className="p">
                <p>{item.a}</p>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
  )
}

export default FAQ;