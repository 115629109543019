import { useState, useEffect } from 'react';

const useUTMParams = () => {
    const [utmParams, setUTMParams] = useState({});

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_id'];
        const utmData = params.reduce((acc, param) => {
            const value = searchParams.get(param);
            if (value) acc[param] = value;
            return acc;
        }, {});
        
        setUTMParams(utmData);
    }, []);
    
    return utmParams;
};

export default useUTMParams;