import React from 'react';
import { Link, useParams } from "react-router-dom";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import CalendarWrapper from '../../../components/library/calendarWrapper/CalendarWrapper';
import Video from '../../../components/library/Video';
import { Mixpanel } from "../../../mixpanel";
import "./NewTech.scss";
import { isMobile } from 'react-device-detect';
import UseTrackScrollHeight from '../../../hooks/UseTrackScrollHeight';
import BannerCalendarWrapper from '../../../components/library/calendarWrapper/BannerCalendarWrapper';

const NewTech = () => {
  const { source } = useParams();
  Mixpanel.track('article new-tech-sm pageview', { source });  

  return (
    <>
      <div className="new-tech-container">
        <div className="main-content">

          <h1>
            טכנולוגיה חדשה ברישוי בנק ישראל חוסכת בממוצע 261,000 ש”ח לעובדי הייטק בעמלות ובריביות מופרזות
          </h1>

          <p>
            סטארט אפ הפינטק הישראלי Better פיתח טכנולוגיה שחוסכת מאות אלפי שקלים לעובדי הייטק בעמלות ובדמי ניהול, בריביות מופרזות ובתשלומים מיותרים תוך שמירה קפדנית על המידע שלהם, והכל בחינם!
          </p>

          <p className='desktop-only'>
            איך זה יכול להיות? פגשנו את טל עקרוני מנכ"ל Better כדי להבין איך האלגוריתם שלהם עובד, למה בחודש הקרוב זה עתיד להיות בחינם, ואיך הוא מצליח לחסוך להייטקסיטים מאות אלפי שקלים שבחלק מן הפעמים הם כלל לא מודעים לקיומם?
          </p>

          {
            isMobile ? (
              <Video
                configs={{ controls: true, autoPlay: true }}
                poster={"/images/all/video-thumbnail.png"}
                sourceList={[{
                  src: "https://better-website-static.s3.eu-central-1.amazonaws.com/mobile.mp4",
                  type: "video/mp4"
                }]}
              />
            ) :
              (
                <Video
                  configs={{ controls: true, autoPlay: true }}
                  poster={"/images/all/video-thumbnail.png"}
                  sourceList={[{
                    src: "https://better-website-static.s3.eu-central-1.amazonaws.com/desktop.mp4",
                    type: "video/mp4"
                  }]}
                />
              )
          }

          <p className='mobile-only'>
            איך זה יכול להיות? פגשנו את טל עקרוני מנכ"ל Better כדי להבין איך האלגוריתם שלהם עובד, למה זה בחינם בחודש הקרוב ואיך הוא מצליח לחסוך להייטקסיטים מאות אלפי שקלים שלא מעט פעמים הם לא יודעים על קיומם.
          </p>

          <h2 className='desktop-only'>
            נפרדים מהאותיות הקטנות ונותנים לאלגוריתם של Better לעבוד בשבילכם
          </h2>

          <p>
            מי לא מכיר את תחושת הבלבול ואת חוסר האונים כאשר ממלאים לחשבת השכר את הטפסים עם נקודות הזיכוי למס, או את הפגישה עם הסוכן הפנסיוני שאנחנו רק רוצים שתסתיים כבר? מי טורח להסתכל על המכתבים מהבנק ולקרוא את רשימת העמלות שמסתתרות מאחורי הדף? למי יש כוח להגיע הביתה אחרי יום עמוס ולהתעסק עם האותיות הקטנות. אז הנה התשובה: למי שיש כוח, זה לאלגוריתם של Better.
          </p>

          <p>
            אחרי סטארטפ מצליח בתחום הפינטק, הוצאת כ- 20,000 חיילים מהמינוס במסגרת השירות הצבאי שלהם וחילוץ של כ- 4,000 משפחות מחובות, טל עקרוני מנכ"ל Better החליט לחזור לתשוקה שלו ולעזור לאנשים לחסוך כסף בכל העולמות הפיננסים.
          </p>

          <h2>
            טל, מה מביא אדם עם 15 שנות ניסיון עסקי בתחום הפיננסים וההייטק לבחור להתמקד בלעזור לישראלים לחסוך כסף?
          </h2>

          <p>
            מה שהביא אותי למסע הזה זה התסכול מזה שכבר 16 שנים אני עושה הרצאות בחינוך פיננסי והן לא יעילות. עם הזמן הבנתי מה מונע מאנשים ליישם את הצעדים הפשוטים שיכולים לחסוך להם מאות אלפי שקלים. עם התנהלות נכונה היום אפשר לקבל עוד 2000-3000 ש”ח בחודש בגיל הפרישה.
          </p>

          <p>
            במהלך השנים גיליתי שיש 4 כשלים שמונעים מאנשים לחסוך מאות אלפי שקלים:
          </p>

          <p>
            <span>
              כשל ראשון - אנשים לא יודעים מה יש להם.
            </span>
            אנשים לא יודעים מה יש להם. איזה ביטוח פנסיוני, איזו משכנתא ואיך היא בנויה, הם לא יודעים האם מס הכנסה חייב להם כסף. הם לא מודעים למתרחש במרחבים הללו, ולכן גם לא מתפנים כדי לעקוב ולבדוק את זה.
          </p>

          <p>
            <span>
              כשל שני - אנשים שיודעים מה יש להם אבל לא יודעים אם זה טוב ביחס למה שאפשר לקבל בשוק.
            </span>
            אני לא יודע להגיד מה העלויות שאני משלם לעומת השוק, האם דופקים אותי או לא ואין מקום נוח כדי לבדוק את זה ויש את התחושה שאני מכיר את מנהל הסניף והוא "בטח דאג לי להנחה" במשכנתא, או שהסוכן שלי ממש מקצוען ודואג לי.
          </p>

          <p>
            <span>
              כשל שלישי - אנשים שמבינים שדופקים אותם אבל לא יודעים מה לעשות כדי לשנות את זה.
            </span>
            האם אני יכול לעשות משא ומתן? למי פונים? לסוכן שלי או לחברת הפנסיה? לבנקאית או לחברת כרטיסי האשראי? ליועץ המס או ליועץ המשכנתא? יש בלבול גדול והבנה שהתהליך מתיש.
          </p>

          <p>
            <span>
              כשל רביעי - גם אם אתם יודעים בדיוק מה לעשות, אין לאף אחד כח לעשות את זה.
            </span>
          </p>

          <p>
            לכן הקמנו את Better.
          </p>

          <div className='link-wrapper'>
            <AnchorLink offset='88' href='#zoom-schedule'>
              תאמו היום שיחת הכרות ב-Zoom עם כלכלן של Better והצטרפו לאלפי הייטקיסטים שחוסכים מאות אלפי שקלים בעמלות ודמי ניהול מוגזמים
            </AnchorLink>
          </div>
          <BannerCalendarWrapper Calendar={CalendarWrapper} /> {/**bigsize */}
        </div>
        <CalendarWrapper />
      </div>
    </>
  )
}

export default NewTech