import { useEffect, useCallback } from 'react';
import { getScrollPercent } from '../helpers/getScrollPercent';

// This hook can be call multiple time from same page, 
// to invoke multiple callbacks or to track different scroll heights.

const UseTrackScrollHeight = ( scrollTarget, callback ) => {

  // Invoke callback when scrolling to target percentage:
  let achievedTarget = false;

  const handleScroll = useCallback(
    () => {
      if ( getScrollPercent() >= scrollTarget && !achievedTarget) {
        callback();
        achievedTarget = true;
      }
    }, 
    [callback, scrollTarget]
  );
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
      
  return null;
}

export default UseTrackScrollHeight;

