import { useParams, useHistory } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { getFeatureVersion } from "../../abTesting";
const CampaignTypes = {
    taboola: 'taboola',
    outbrain: 'outbrain',
    linkedin: 'linkedin',
    youtube: 'youtube',
    facebook: 'facebook',
    twitter: 'twitter',
    webbee: 'webbee',
    geektime: 'geektime',
    google: 'google',
    hr: 'hr',
    tiktok: 'tiktok',
    reddit: 'reddit',
};

/**
 * sourceType: direct, lecture, campaign, referral, affiliate, web1, web2, employeeReferral, spouse, relative, reserveForces
 * campaign: If sourceType is campaign then the options are as above. If sourceType is affiliate then the value is the affiliate name.
 * campaingName: The name of the campaign. For example: "facebookBRD91-v1"
 */

const getCampaignType = (source) => {
    if (!source) return;
    return Object.values(CampaignTypes).find(item => source.toLowerCase().includes(item));
}

export default function useCampaign(props) {
    const { source } = useParams();
    const [campaignType, setCampaignType] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const { companyId, refId, userId, affiliateCampaign, webCampaign, isFromHomePage } = props;
    const history = useHistory();

    useEffect(() => {
        // When user is coming from some campaign-page but then passed to another regular page, save the original source where he came from.
        let campaignType = source || document.referrer;
        let campaignName = webCampaign ? webCampaign : source;

        const landingPageVersion = getFeatureVersion('landingPageVersion');
        if (landingPageVersion && campaignName && !webCampaign && !isFromHomePage) {
            campaignName += `-${landingPageVersion}`;
        }

        if (isFromHomePage) {
            if (campaignName) {
                campaignName += '-homepage';
            }
            else {
                campaignName = 'homepage';
            }
        }

        setCampaignName(campaignName);
        setCampaignType(getCampaignType(campaignType || document.referrer));
    }, [source])

    const handleEvent = () => {
        window.location.href = "/thanks";
        // history.push('/thanks');
    }

    const getCampaignValue = () => {
        if (campaignType)
            return campaignType;
        else if (affiliateCampaign)
            return affiliateCampaign;
        else if (webCampaign) {
            if (window.location.href.includes("web1"))
                return 'web1';
            else if (window.location.href.includes("web2"))
                return 'web2';
        }
        else if (companyId)
            return companyId;
        else if (userId)
            return userId;
        else if (refId)
            return refId;
        else if (window.location.pathname.toLocaleLowerCase().includes('aiexpert2'))
            return 'AiExpert2';
        else if (window.location.pathname.toLocaleLowerCase().includes('aiexpert'))
            return 'AiExpert';
    };

    const getSourceType = () => {
        if (campaignType)
            return 'campaign'
        else if (affiliateCampaign)
            return 'affiliate';
        else if (webCampaign) {
            if (window.location.href.includes("web1"))
                return 'web1';
            else if (window.location.href.includes("web2"))
                return 'web2';
        }
        else if (companyId)
            return 'company';
        else if (userId)
            return 'user';
        else if (refId)
            return 'referral'
    }

    return useMemo(
        () => ({
            campaignType,
            campaignName, // utm_term
            handleEvent,
            getCampaignValue, // utm_source
            getSourceType // utm_campaign
        }),
        [campaignType, handleEvent, getCampaignValue, getSourceType]
    );
}






