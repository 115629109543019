import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import ScrollToTop from './components/library/ScrollToTop';
import Header from './components/library/Header';
import Footer from './components/library/Footer';
import SecurityAndPrivacy from './pages/securityAndPrivacy/SecurityAndPrivacy';
import HowItWorks from './pages/howItWorks/HowItWorks';
import AboutUs from './pages/aboutUs/AboutUs';
import Home from './pages/home/Home';
import QANDA from './pages/qAndA/QANDA';
import Contact from './pages/contact/Contact';
import ContactSent from './pages/contactSent/ContactSent';
import Companies from './pages/companies/Companies';
import NewTech from './pages/articles/newTechTbl/NewTech';
import HowToSave from './pages/articles/newTechTbl/HowToSave';
import AiExpert from './pages/articles/newTechTbl/AiExpert';
import NewLandingPage from './pages/articles/newLandingPage/NewLandingPage';
import NewTechSm from './pages/articles/newTechSm/NewTechSm';
import ThankYouPage from './pages/thankYou';
import { hideHeader, hideFooter } from './helpers/navigation';

const RouterContent = () => {
    const [displayHeader, setDisplayHeader] = useState(true);
    const [displayFooter, setDisplayFooter] = useState(true);
    const [showZoomButton, setShowZoomButton] = useState(true);
    const location = useLocation();

    useEffect(() => {
        if (hideHeader(location.pathname)) {
            setDisplayHeader(false);
        }
        if (hideFooter(location.pathname)) {
            setDisplayFooter(false);
        }
        if (location.pathname.toLowerCase().includes('thanks')) {
            setShowZoomButton(false);
        } else {
            setShowZoomButton(true);
        }
    }, [location]);
    return (
        <>
            <ScrollToTop />
            {displayHeader && <Header showZoomButton={showZoomButton} />}
            <Switch>
                <Route exact path="/newTech" component={NewTech} />
                <Route exact path="/newTech/:source" component={NewTech} />
                <Route exact path="/HowToSave" component={HowToSave} />
                <Route exact path="/HowToSave/:source" component={HowToSave} />
                <Route exact path="/AiExpert" component={AiExpert} />
                <Route exact path="/AiExpert/:source" component={AiExpert} />
                <Route exact path="/AiExpert2" component={() => <AiExpert shortTitle={true} />} />
                <Route exact path="/AiExpert2/:source" component={() => <AiExpert shortTitle={true} />} />
                <Route exact path="/NewTechSm" component={NewLandingPage} />
                <Route exact path="/NewTechSm/:source" component={NewLandingPage} />
                <Route exact path="/NewTechSm/a/:affiliateCampaign" component={NewLandingPage} />
                <Route exact path="/NewTechSm/web1/:webCampaign" component={NewTechSm} />
                <Route exact path="/NewTechSm/masleka/:source" component={NewTechSm} />
                <Route exact path="/NewTechSm/harb/:source" component={NewTechSm} />
                <Route exact path="/NewTechSm/pension/:source" component={NewTechSm} />
                <Route exact path="/NewTechSm/web2/:webCampaign" component={NewLandingPage} />
                <Route exact path="/HowItWorks" component={HowItWorks} />
                <Route exact path="/FAQ" component={QANDA} />
                <Route exact path="/SecurityAndPrivacy" component={SecurityAndPrivacy} />
                <Route exact path="/AboutUs" component={AboutUs} />
                <Route exact path="/Contact" component={Contact} />
                <Route exact path="/noConvenientTime" component={Contact} />
                <Route exact path="/meetingToday" component={Contact} />
                <Route exact path="/moreInfo" component={Contact} />
                <Route exact path="/ContactSent" component={ContactSent} />
                <Route exact path="/Companies" component={Companies} />
                <Route path="/free/:companyId" component={Home} />
                <Route path="/free2/:companyId" component={Home} />
                <Route path="/faction/:name" component={Home} />
                <Route path="/miluim/:family" component={Home} />
                <Route path="/user/:userId" component={Home} />
                <Route path="/friend/:refId" component={Home} />
                <Route path="/a/:affiliateCampaign" component={Home} />
                <Route exact path="/" component={Home} />
                <Route exact path="/Thanks" component={ThankYouPage} />
                <Route exact path="/:source" component={Home} />
                <Redirect to="/" />
            </Switch>
            {displayFooter && <Footer />}
        </>
    );
};

export default RouterContent;