import React, {useEffect, useState} from 'react';
import CalendarWide from '../CalendarWide';
import "./CalendarWrapper.scss";
import {useHistory, useParams} from "react-router-dom";

const CalendarWrapper = (props) => {
    const {source} = useParams();

    return (
        <div className="calendar-wrapper">
            <h1>
                תאמו עכשיו שיחת היכרות ב-ZOOM
            </h1>

            <h3>
                בחרו את המועד הנוח ביותר לשיחה בת 30
                דקות עם מומחי הפיננסים שלנו, נשלח לכם זימון למייל ונתקשר מולכם בוואטסאפ:
            </h3>

            <CalendarWide campaignType={source} {...props} />
        </div>
    )
}

export default CalendarWrapper;