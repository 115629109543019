// List of pages to prevent menubar (Header) display:
const noHeaderDisplay = [
    'newTechSm',
    'newlandingpage',
];

// List of pages to prevent Footer display:
const noFooterDisplay = [
    'newTechSm',
    'newlandingpage',
    'Thanks',
];

export function hideHeader( pathname ) {
    let preventHeader = false;
    noHeaderDisplay.forEach( ( pageName ) => {
        if ( pathname.toLowerCase().includes( pageName.toLowerCase() ) ) {
            preventHeader = true;
        }
    } );
    return preventHeader;
}

export function hideFooter( pathname ) {
    let preventFooter = false;
    noFooterDisplay.forEach( ( pageName ) => {
        if ( pathname.toLowerCase().includes( pageName.toLowerCase() ) ) {
            preventFooter = true;
        }
    } );
    return preventFooter;
}