import React, { useState } from "react";
import "./App.scss";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from 'react-ga';
import ReactModal from 'react-modal';
import RouterContent from "./RouterContent";

ReactGA.initialize('G-CN970MZQ9N');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Router>
                <RouterContent />
            </Router>
            {/* <CookieConsent
                flipButtons
                disableStyles
                location="bottom"
                buttonText="סגור"
                expires={30} >
                {" "}
                <div className="text">
                    <p>
                        {" "}
                        אנחנו משתמשים בעוגיות על מנת שנוכל ליצור את החוויה הטובה ביותר עבורך.
                        המשך הגלישה באתר מהווה הסכמה לשימוש בעוגיות <span onClick={() => setShowModal(true)} className="link">ולמדיניות הפרטיות</span> שלנו.
                    </p>
                </div>
            </CookieConsent> */}
            <ReactModal
                isOpen={showModal}
                overlayElement={(props, contentElement) => <div {...props} onClick={() => setShowModal(false)} > {contentElement} </div>}
                style={{ overlay: { background: 'rgba(0,0,0,0.4)' }, content: { 'max-width': '1000px' } }}
            >
                <div className="close-modal" onClick={() => setShowModal(false)}>X</div>
                <div className="content">
                    <iframe frameborder="0" onmousewheel="" width="100%" height="1800" title="privacy-policy" scrolling="no"
                        src="https://better-website-static.s3.eu-central-1.amazonaws.com/website-privacy.html" />
                </div>
            </ReactModal>
        </>
    );
}

export default App;
