import React from "react";

const SavingsItem = ({ customersImg, customersLogo, savingsText }) =>  {

  return (
    <div className="savings-item">
      <img className="customers-img" src={ customersImg } alt="customers-img" />
      <div className="logo-wrapper">
        <img className="customers-logo" src={ customersLogo } alt="customers-logo" />
      </div>
      <h3>{ savingsText.name }</h3>
      <h3>{ savingsText.title }</h3>
      <h3 className="savings">{ savingsText.saved }</h3>
    </div>
  );
}

export default SavingsItem;