import { useEffect, useState } from 'react';

// Custom hook for observing whether a DOM element is in the viewport.
export default function useIntersectionObserver(ref, options = { root: null, rootMargin: '0px', threshold: 0.4 }) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) =>
            setIntersecting(entry.isIntersecting)
            , options
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.disconnect();
            }
        };
    }, [ref.current]);

    return isIntersecting;
}


