
import mixpanel from 'mixpanel-browser';
mixpanel.init('b7a9b6aa1e8217857849f0dfa1f50e5b', {debug: true});

const envCheck = process.env.NODE_ENV === 'production';

const actions = {
    identify: (id) => {
        if (envCheck)
            mixpanel.identify(id);
    },
    alias: (id) => {
        if (envCheck)
            mixpanel.alias(id);
    },
    track: (name, props) => {
        console.log("Mixpanel track: ", name, props);
        if (envCheck)
            mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (envCheck)
                mixpanel.people.set(props);
        },
    },
    getId: ()=> mixpanel.get_distinct_id()
};

export let Mixpanel = actions;