import React from 'react';
import './whatsAppIcon.scss';

const WhatsAppIcon = () => {
  return (
    <a
      href="https://wa.link/better"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-icon"
    >
        <img src="/images/all/whatsapp-btn.svg" alt="whatsapp-icon" />
    </a>
  );
};

export default WhatsAppIcon;
