import React from "react";
import "../../App.scss";
import "../contact/Contact.scss";
import { Link } from "react-router-dom";
import AnchorLink from 'react-anchor-link-smooth-scroll'

function ContactSent() {
  return (
    <>
      <div className="contact">
        <div className="contact-form container">
          <div className="right-side">
            <h1 className="title">
              יש לכם שאלות? צריכים עוד קצת מידע? הגעתם למקום הנכון!
            </h1>
            <p className="sup-title">
              תוכלו לתאם איתנו{" "}
              <AnchorLink offset='88' href='#zoom-schedule'>שיחה קצרה בזום</AnchorLink>
              {" "}
              <br className="mobile-break" />
              או להשאיר את הפרטים כאן
            </p>
            {/* <img src="/images/all/Contact-sent.png" /> */}
            <div className="recevied-massage">
              <img src="/images/all/Contact-Sent-icon.png" />
              <h6>ההודעה שלך נשלחה</h6>
              <p>נהיה בקשר בקרוב!</p>
            </div>
          </div>
          <div className="left-side">
            <img
              className="web"
              src="/images/all/Contact-image.png"
              alt="circular"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactSent;
