import React from 'react';
import jsonData from '../../../data/q&a.json';
import styles from './FAQ.module.css';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
const FAQ = () => {
    const [checkedIndex, setCheckedIndex] = useState(null);
    const handleClick = (i) => {
        if (checkedIndex === i) {
            setCheckedIndex(null);
        } else {
            setCheckedIndex(i);
        }
    }
    return (
        <section id="q&a">
            <div className={styles.question}>
                <div className={styles.accordion}>
                    {jsonData.map((item, i) => (
                        <div key={'faq-' + i} className={styles.c}>
                            <input type="checkbox" id={'faq-' + i} checked={checkedIndex === i} onChange={() => handleClick(i)} />
                            {
                                !isMobile ?
                                    <h1>
                                        <label htmlFor={'faq-' + i}>{item.q}</label>
                                    </h1>
                                    :
                                    <h1 >
                                        <span onClick={() => handleClick(i)} style={i === checkedIndex ? { fontWeight: 'bold', fontSize: '15px' } : {}}>{item.q}</span>
                                    </h1>
                            }
                            <div className={styles.p}>
                                <p>{item.a}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQ;
