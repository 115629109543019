import React, { useRef } from 'react';
import './AiExpert.scss';
import { useParams } from "react-router-dom";

import AnchorLink from 'react-anchor-link-smooth-scroll';
import CalendarWrapper from '../../../components/library/calendarWrapper/CalendarWrapper';
import { Mixpanel } from "../../../mixpanel";
import UseTrackScrollHeight from '../../../hooks/UseTrackScrollHeight';
import BannerCalendarWrapper from '../../../components/library/calendarWrapper/BannerCalendarWrapper';

const AiExpert = ({shortTitle = false}) => {
    const { source } = useParams();
    const refForBanner = useRef();

    Mixpanel.track('article ai-expert pageview', { source });
    
    const userClick = (event) => {
        Mixpanel.track(event, { source });
    }

    const title = shortTitle ? "ההמלצה של צוף בראור אינפלואנסר הבינה המלאכותית להייטקיסטים" : "\"דמיינו סוכן AI שמתעסק לכם בכל הבלאגן הפיננסי: פנסיות, הלוואות, משכנתא, שיחות טלפון מעיקות, רבע אחוז פה, עשירית אחוז שם, הכל. כרגע זה עוד ממש בדמיון, אבל מצאתי את הדבר הכי קרוב לזה - Better. חינם. קודם כל חינם. וגם הזדמנות נדירה לתמוך בעשייה שלי. בואו רגע.\"" 

    return (
        <div className='aiexpert-container'>
            <div className="main-content">
                <h1>{title}</h1>
                <p>כך נפתח השרשור של צוף בר אור, משפיען בתחום הבינה המלאכותית בטוויטר. בשרשור שזכה לתהודה של עשרות אלפי עוקבים בצורה אורגנית משתף צוף את החוויה האישית שלו עם הטכנולוגיה והשירות של .Better – חברת פינטק אשר מביאה את המהפכה בעולם הבנקאות הפתוחה אל חייהם של אלפי הייטקיסטים בישראל, חותכת להם את העמלות וחוסכת להם מאות אלפי שקלים.</p>
                <img src="/images/all/aiexpert/1.png" alt="ai-robbot" />
                <h6>את התמונה יצר צוף בר אור באמצעות מידג'רני</h6>
                <p>הפוסט של צוף עלה בטוויטר בקיץ האחרון, כמה חודשים טרם כתיבת הכתבה, מה שנחשב כהיסטוריה ברשתות החברתיות אך הסקירה המקיפה, הכנה והמדויקת הצליחה להעביר  הסיפור של  Better בצורה נגישה ושונה ואף הניע אלפי משתמשים לתאם פגישת היכרות ולהפוך ללקוחות החברה.  אומנם שיתוף הפעולה עם צוף היה בתשלום אבל כל מה שכתב היה על דעתו מהתנסותו האישית.</p>
                <h2>המפגש הראשון עם בטר</h2>
                <img src="/images/all/aiexpert/2.png" alt="zoom-with-zoof" />
                <h6>צילום מסך מפגישת ההכרות בזום של צוף עם אריאל שירי, אחראית על קליטת לקוחות חדשים בבטר</h6>
                <p>"מהפכת הבנקאות הפתוחה מאפשר לחברות כמו בטר לעשות לנו סדר בכסף שלנו ולהוריד לנו את הנושא הזה מהראש בקלות שלא תאמן, נפגשתי עם אריאל להיכרות קצרה בזום והטכנולוגיה עשתה את השאר. זה ככה פשוט", מספר צוף.</p>
                <div ref={refForBanner} className='link-wrapper' onClick={() => userClick("schedule zoom click")}>
                    <AnchorLink offset='88' href='#zoom-schedule'>
                        תאמו היום שיחת הכרות ב-Zoom עם כלכלן של Better והצטרפו לאלפי הייטקיסטים שחוסכים מאות אלפי שקלים בעמלות ובדמי ניהול מוגזמים
                    </AnchorLink>
                </div>
                <p>"כמה ימים אחרי פגישת ההיכרות וכבר קיבלתי את הוואצאפ הראשון מבטר אחרי שהטכנולוגיה בדקה את הפנסיה שלי וזה אולי הרגע הכי מרשים בכל הסיפור שלי עם החברה, הרי איך זה בחינם? הם מרוויחים מהעברה שלנו בין הגופים הפיננסים, אין פה קסם. כאן קצת בדקתי אותם בשקט, כי עשיתי לא מזמן סקר רציני ששרף לי הרבה זמן על תנאי הפנסיה הכי טובים. האינטרס הצר שלהם הוא להעביר אותי. זה לא קרה."</p>
                <img src="/images/all/aiexpert/3.png" alt="whatsapp-with-zoof1" />
                <h6>צילום מסך מהודעת הווטסאפ שנשלחה לצוף לאחר שהאלגוריתם והצוות סיימו לבחון את הפנסיה שלו</h6>
                <p>"כששאלתי אותם בדיעבד הם אמרו דבר ממש פשוט - יש לנו אלגוריתם ניטרלי, משפר? נציע. לא משפר? לא נציע. האסטרטגיה היא לשמור אותי כלקוח לטווח הארוך, לא לחפש רווחים בטווח קצר. מאז כל כמה ימים קופצת לי הודעה בוואצאפ על איזה שדרוג, בלי בלבולי מוח של סוכן בטלפון בדיוק כשאין לי זמן. ובטח שלי אישית כל ההתנהלות בשיחות טלפון הייתה מחסום ללהתעסק בזה (דור הZ? הY? - למרות שאפשר גם לדבר איתם בטלפון אם רוצים).</p>
                <img src="/images/all/aiexpert/4.png" alt="whatsapp-with-zoof2" />
                <h6>צילום מסך מהודעת הווטסאפ שנשלחה לצוף לאחר שהאלגוריתם והצוות בחנו את הפקדונות של צוף </h6>
                <p>היוזקייסים בגדול בלתי נגמרים. עברתם חברה והתקשרו אליכם לשיחת שימור? נציג של Better יעלה (חינם) בלייב איתכם לשיחה לוודא שלא מערבבים אתכם. מחזור הלוואות, החזרי מס, השקעות, איפה שאפשר להפעיל אופטימייזר הם יאפטמו, ומשיחה עם הCTO שלהם יש עוד תכניות גדולות. נחזור אלינו - זה לאורך זמן. וואנס אתם במערכת, כל פעם שישתנה משהו בשוק יש לכם אלגוריתם שכבר יעשה עבורכם את האופטימיזציה וישלח רשמים בוואצאפ, ואני לא צריך לספר שכל רבע אחוז יכול להצטבר לעשרות אלפים של חסכון. השרשור הזה לא בתשלום, ואני מהותית לא מסכים לשווק דברים כמו קורסים. הדיל שלי עם Better הוא כזה - יש לי לינק אפיליאט.השלמתם איתם שיחת היכרות ונכנסתם לאופטימייזר החינמי שלהם? תרמתם לעשייה שלי, שאני מאד רוצה להרחיב. אני חושב על הרבה דברים - יוטיוב, אודיו, אתר אינטרנט או כל פורמט אחר שעלול לעניין. חינם, בלי מחוייבות, בלי פרטי אשראי, רק תפתחו להם את הדלת ותתחילו לקבל הצעות שדרוג. באתר שלהם תראו מספרים אמיתיים של חיסכון של מאות אלפים. יאללה, בהצלחה, ותודה אם בחרתם לפגוש אותם דרך הלינק הזה:</p>
                <a href="https://allbetter.co.il/NewTechSm/PsTsoofBarOr" target="_blank" rel="noopener noreferrer">https://allbetter.co.il/NewTechSm/PsTsoofBarOr</a>
                <p>(ומוזמנים לוודא איתם בשיחה שזה אכן נרשם שהגעתם ממני)"</p>
                <h2>וכמו לכל שרשור טוב בטוויטר הגיעו השאלות הקשות! אספנו אותן יחד עם התגובות שלנו ושל צוף.</h2>
                <p><span className="text-blue">תגובה לפוסט בטוויטר:</span> חינם זו המילה האחרונה שאני רוצה לשמוע בהקשר של סוכן פיננסי. חינם משמעו שאתה לא הלקוח.</p>
                <p><span className="text-orange">תגובת צוף לקורא בטוויטר:</span> הם דווקא ממש שקופים לגבי מי הלקוח ואיך הם מרוויחים. גם ממש דיברתי על זה בפוסט, שזה החשש המיידי שלי כשמשהו בחינם - וראיתי שהם המליצו לי לא לעבור פנסיה, מה שכאילו בניגוד לאינטרס שלהם. זה כמובן אנקדוטלי, אבל מסתדר עם היגיון עבודה שלהם. יש אלגוריתם. אם הוא מוציא שיפור, שולח אליך. לא - לא.</p>
                <AnchorLink offset='88' href='#zoom-schedule' className='clickable-image' onClick={() => userClick("schedule zoom click")}>
                    <img src="/images/all/aiexpert/5.png" alt="" style={{ width: "70%" }} />
                </AnchorLink>
                <p><span className="text-blue">תגובת קורא בטוויטר:</span> מכיר את הסיסמא. יש להם מחוייבות מול הרגולטור מה מותר לעשות עם הדאטה (אסור להעביר או למכור). הם רק הברוקר בין החברות השונות, הם עושים את הכסף על המעברים :)</p>
                <p><span className="text-orange">תגובת צוף לקורא בטוויטר:</span> נכון, בגלל זה התפלאתי שהם לא הציעו לי לעבור פנסיה ופשוט כתבו לי שזה אופטימלי. אפשר להיות ציניים ורק לחשוב איך כולם מנסים לדפוק אותנו, אני בגדול מעודד את מהפכת הפינטק הישראלית - וגם יודע שיש רגולציה שמספקת לנו איזו שהיא הגנה.אין תחליף ללהיות צרכן חכם, אני רואה בהם שירות טוב לקבל הרבה הצעות בצורה קומפקטית ונוחה. אני אחליט :)</p>
                <p><span className="text-blue">תגובת קורא בטוויטר:</span>  נשמע מצוין, רק שאלה - נתוני הגישה למסלקה מאוד יקרים - מה מבטיח שהם לא ימכרו אותם לצד שלישי.</p>
                <p> <span className="text-orange">תגובת צוף לקורא בטוויטר:</span> למיטב ידיעתי, יש כללים ממש ברורים במהפכת הבנקאות הפתוחה לגבי מה מותר לחברות פינטק לעשות עם המידע הזה. הם גם כותבים שלא יעבור לשום מקום. אבל יודע מה - אדבר איתם בעצמי, רק לקבל על זה את החותמת :)</p>
                <p><span className="text-blue">תגובת קורא בטוויטר:</span> תודה רבה על השרשור וההשקעה. מבחינתי ברגע ששמת לינק איפליאט אתה כבר לא אובייקטיבי, ויש לך אינטרס להסתיר את החסרונות של השירות, בהצלחה.</p>
                <p><span className="text-orange">תגובת צוף לקורא בטוויטר:</span> תודה על השאלה. בטר פנו אלי לשיתוף פעולה. לפני שיתוף הפעולה נרשמתי בעצמי יחד עם עוד כמה בני משפחה כדי לבחון את השירות ולעבור את התהליך בעצמי. אחרי שהתרשמתי מהשירות עצמו ושוחחתי עם בכירים בחברה הסכמתי לשיתוף הפעולה, וBetter בחרו לקדם את הפוסט שכתבתי אם תציג את החלק התחתון, אמור להיות כתוב שם "מקודם ע"י Better". כמובן שנתתי הסכמה לעשות את זה. אין ספק שלינק שאני מתוגמל ממנו הוא לא תמים בהוויתו, אבל חשוב לי להבהיר שני דברים: 1. כשאני משתף פעולה אני גם מסכן את עצמי והמוניטין שלי, במידה והם לא בסדר - אני לא בסדר. זו ההמלצה אותנטית. 2. פנו אלי עם שיתופי פעולה סליזיים של כל מיני קורסים ושטויות. אני לא בטוויטר כדי למכור דברים, בחרתי לשתף איתם פעולה כי יש כאן שירות חינמי שאני מאמין שאשכרה יכול לעזור לפחות לחלק מהעוקבים שלי. אני מאמין שיש פה WIN WIN WIN (לי, לעוקבים, לבטר). אגב, 35K~ חשיפות היו אורגניות לחלוטין בפוסט הזה. הפרסום הוא בוסט קטן יחסית :) מוזמן לשאול כל שאלה על מהות שיתוף הפעולה, אני שקוף לגמרי.</p>
                <p><span className="text-blue">תגובת קורא בטוויטר:</span> מה ההבדל בינם לבין riseup?</p>
                <p><span className="text-orange">תגובת צוף לקורא בטוויטר:</span> רייזאפ היא אפליקציה שעוזרת לך לנהל הוצאות והכנסות בשוטף. גם תוצר של מהפכת הבנקאות הפתוחה, דבר נפלא. בטר מתעסקים בלמצוא לך תנאים יותר טובים לכל השירותים הפיננסיים שאתה צורך דרך אלגוריתם מיוחד שהם פיתחו שכל הזמן סורק ומחפש הצעות טובות.</p>
                <p><span className="text-blue">תגובת קורא בטוויטר:</span>  כל כך לא נכון. דיברתי איתם לגבי הפנסיה והם המליצו לי לעבור לכלל בגלל שדמי הניהול נמוכים.רק חבל שכלל בתחתית הרשימה בתשואות.</p>
                <p><span className="text-orange">תגובת צוף לקורא בטוויטר:</span>  תראה, כותב פה על דעתי בלבד, כצרכן ובלי קשר לבטר: תשואות עבר בטווח הקצר הן מדד גרוע מאד לבחירת מוצר פיננסי, ביחוד פנסיה. עברתי לאטשולר שחם כשהם היו הדבר הכי חם בשוק ושברו את כולם בתשואות, וכמובן שברגע שהצטרפתי הם הפכו להיות בתחתית של כל הרשימות. יש הרבה מזל בסיפור של תשואות בטווח הקצר, והאמת שבטר אמרו לי כבר בשיחה הראשונה שהם לא מסתכלים על תשואות עבר בכלל. כל 5 שנים מישהו אחר בתחתית ומישהו אחר למעלה, אבל בסופו של דבר כולם עולים עם השוק, והתנאים שתקבל יקבעו כמה ינגסו לך מהצבירה. שוב, בלי שום קשר לשירות שBetter מציעים.</p>
                <p><span className="text-blue">תגובת קורא בטוויטר:</span> עד היום, כל המיזמים שניסו לעשות משהו דומה נכשלו מסיבה אחת מרכזית - ברגע שאתה משלם 18 ש''ח על כל מסלקה שאתה מפיק ללקוח - אתה חייב לבצע פעולות, או שאתה פושט רגל. היו לא מעט חברות שהתחילו עם אידיאלים כמו שאתה מתאר, ומהר מאוד שינו את המודל כדי לשרוד - ראה ערך וובי, רובין, זה ברור </p>
                <p><span className="text-purple">תגובת החברה לקורא בטוויטר:</span> אנחנו מייצרים הכנסות על בסיס הצלחה - אם הלקוחות שלנו חוסכים כסף בזכותנו, גם אנחנו מרוויחים. היות שאנחנו מצליחים בזכות הטכנולוגיה לחסוך כספים משמעותיים ללקוחותינו, גם בסיס ההכנסות שלנו איתן ומאפשר לנו להמשיך לצמוח ולהגדיל עוד יותר את הערך ללקוחותינו. המודל איתן והוכיח את עצמו השנה והאידיאלים ישמרו.</p>
                <p><span className="text-blue">תגובת קורא בטוויטר:</span> אני לא מאחל לך להגיע ליום שתצטרך לתבוע ביטוח על דברים כאלו ואחרים, ואז תסתמך על העזרה של הבוט בווטאספ.</p>
                <p><span className="text-purple">תגובת החברה לקורא בטוויטר:</span> יש לנו עובד שכל תפקידו הוא לוודא שאף פניה לא נופלת בין הכסאות, מעבר האחריות של כל נציג לדאוג לך לשירות הטוב ביותר. מקווה שצוף לא יצטרך אבל אם וכאשר אנחנו ערוכים ומוכנים לתת מענה איכותי ומהיר.</p>
                <p><span className="text-blue">תגובת קורא בטוויטר:</span> סוכנות ביטוח עם שת"פ עם יועץ מס שעשתה מהלך שיווק גאוני. אני מתערב שאין פה שום AI וגם אם יש זה מיותר. במבחן התוצאה וחוויית הלקוח הם לא נותנים דבר שסוכנות ביטוח טובה לא עושה בשבילך.</p>
                <p><span className="text-purple">תגובת החברה לקורא בטוויטר:</span>  היי דורון, יש לנו ארבעה רשיונות פיננסים. מציעים "קצת יותר" מייעוץ מס וסוכנות ביטוח ;) למשל, סריקת פקדונות והלוואות על בסיס קבוע כדי לוודא שאתה עם הריבית הכי טובה שיש בשוק, בדיקת תמהיל המשכנתא שלך כדי שתמיד תשלם את המינימום האפשרי וזו רק דוגמא. זה בזכות הטכנולוגיה שפיתחנו שחותכת עבורך עמלות מיותרות במלא תחומים. לפי הרישום שלנו עלית לשיחת הכרות ולא המשכת, נשמח לעמוד לרשותך ותוכל לחוות את חווית הלקוח שלנו כמו שצריך ואז תוכל באמת לכתוב על מבחן התוצאה. שנה טובה :)</p>
                <p><span className="text-blue">תגובת קורא בטוויטר:</span> כן, לתת לתוכנה בלי שום כתובת להתעסק בכספים שלי, נשמע כמו רעיון חסין תקלות לגמרי, מפוקח היטב ובטח שלא ניתן להשפעה חיצונית אינטרסנטית…</p>
                <p><span className="text-purple">תגובת החברה לקורא בטוויטר:</span> היי אריאל, אנחנו לא תוכנה. אנחנו חברת טכנולוגיה שמשתמשת בהמלצות שמייצרים האלגוריתמים שלנו כדי לחתוך לך בעמלות. לאחר שמתקבלת ההמלצה היא עוברת לצוות האנושי הנפלא שלנו שבודק אותה ומסביר לך את ההמלצה בצורה פשוטה. מוזמן להתנסות. מהפכת הבנקאות הפתוחה התרחשה לפני שנה וחצי ואיפשרה גישה למידע שלא הייתה קיימת קודם. אנחנו מובילים את המהפכה והטכנולוגיה שלנו פה גם עבורך כדי לחתוך לך את העמלות. מוזמן להתנסות</p>
                <p><span className="text-blue">תגובת קורא בטוויטר:</span> חלילה שתהיה תביעת אובדן כושר עבודה AI גם יטפל מול חברת הביטוח / קרן הפנסיה? יש המון שיקולים מעבר לדמי ניהול. חברות שמציעות דמי ניהול נמוכים לדוגמה קופות סקטוריאליות ( צה"ל, חברת חשמל, עובדי רשות ועוד. .) מציגות ביצועי חזר לאורך השנים.</p>
                <p><span className="text-purple">תגובת החברה לקורא בטוויטר:</span> אכן יש המון שיקולים, והאלגוריתם שלנו שמקבל החלטות במודול הפנסיה בוחן עשרות פרמטרים נוספים חוץ מדמי ניהול - איכות ניהול ההשקעות, מסלול, ביטוחים, יציבות אקטוארית, יתרון/חיסרון לגודל ורבים נוספים - ההנגשה ללקוח הסופי היא באמצעות החיסכון המצרפי.</p>
                <p>הסקירה המקיפה של צוף הניבה פגישות ולקוחות מרוצים נוספים ל Better.<br /> Better גאה ושמחה לתת שירות גם לצוף בר-אור ומודה לו על שיתוף הפעולה הפורה. הטכנולוגיה שלנו תשאיר לכם הרבה מאוד כסף בכיס, הצטרפו אלינו לשיחת הכרות ונשמח לענות על כל שאלה שיש לכם ולחסוך גם לכם בעמלות מיותרות וריביות מוגזמות!</p>
                <p>הפוסט של צוף כפי שעלה ברשת: <br /><a href="https://twitter.com/tsoofbaror/status/1700041303051079727/photo/1" target="_blank" rel="noopener noreferrer" onClick={() => userClick("zoof twit click")}>https://twitter.com/tsoofbaror/status/1700041303051079727/photo/1</a></p>
            </div>
            <div className='aiexpert-container-calendar-wrapper'>
                <BannerCalendarWrapper
                    Calendar={CalendarWrapper}
                    externalRef={refForBanner}
                    onceShownAlwaysShow={true}
                    title={"תאמו פגישת היכרות"}
                    subtitle={"השאירו פרטים ונחזור אליכם"}
                />
            </div>
        </div>
    )
}

export default AiExpert