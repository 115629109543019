export const LogoDetails = [
    {
        imgUrl: "/images/all/companies-logos/1.png",
        title: "commbox"
    },
    {
        imgUrl: "/images/all/companies-logos/2.png",
        title: "coretigo"
    },
    {
        imgUrl: "/images/all/companies-logos/3.png",
        title: "cyberproof"
    },
    {
        imgUrl: "/images/all/companies-logos/4.png",
        title: "duda"
    },
    {
        imgUrl: "/images/all/companies-logos/PayU.png",
        title: "PayU"
    },
    {
        imgUrl: "/images/all/companies-logos/5.png",
        title: "fyber"
    },
    {
        imgUrl: "/images/all/companies-logos/6.png",
        title: "gilat"
    },
    {
        imgUrl: "/images/all/companies-logos/7.png",
        title: "kela"
    },
    {
        imgUrl: "/images/all/companies-logos/8.png",
        title: "kodem"
    },
    {
        imgUrl: "/images/all/companies-logos/9.png",
        title: "morning"
    },
    {
        imgUrl: "/images/all/companies-logos/10.png",
        title: "next"
    },
    {
        imgUrl: "/images/all/companies-logos/11.png",
        title: "papaya"
    },
    {
        imgUrl: "/images/all/companies-logos/12.png",
        title: "pixelpilot"
    },
    {
        imgUrl: "/images/all/companies-logos/13.png",
        title: "team8"
    },
    {
        imgUrl: "/images/all/companies-logos/14.png",
        title: "gett"
    },
    {
        imgUrl: "/images/all/companies-logos/15.png",   
        title: "wework"
    },
    {
        imgUrl: "/images/all/companies-logos/16.png",
        title: "wiliot"
    },
    {
        imgUrl: "/images/all/companies-logos/SkyHswk Security.png",
        title: "SkyHswk Security"
    },
    {
        imgUrl: "/images/all/companies-logos/17.png",
        title: "yango"
    },
    {
        imgUrl: "/images/all/companies-logos/18.png",
        title: "zencity"
    },
    {
        imgUrl: "/images/all/companies-logos/Aman.png", //19
        title: "Aman"
    },
    {
        imgUrl: "/images/all/companies-logos/Firma Brands.png", //20
        title: "Firma Brands"
    },
    {
        imgUrl: "/images/all/companies-logos/MindSpace.png", //21
        title: "MindSpace"
    },
    {
        imgUrl: "/images/all/companies-logos/Otonomo.png", //22
        title: "Otonomo"
    },
    {
        imgUrl: "/images/all/companies-logos/Zemingo.png", //23
        title: "Zemingo"
    },
];

export const skipIndex = LogoDetails.length % 2 !== 0 ? 9 : undefined