import React from 'react';

export const generateABTesting = (featuresUnderTest) => {
    let abTest = JSON.parse(localStorage.getItem('AB_Test')) || {};
    const modifiedFeatures = {};

    featuresUnderTest.forEach((feature) => {
        if (!abTest[feature]) {
            const randomVariant = Math.random() < 0.5 ? 'v1' : 'v2';
            modifiedFeatures[feature] = randomVariant;
            abTest[feature] = randomVariant;
        } else {
            modifiedFeatures[feature] = abTest[feature];
        }
    });

    localStorage.setItem('AB_Test', JSON.stringify(abTest));
    console.log('abTest object in local storage: ', abTest);

    return modifiedFeatures;
}

export const getABTestingObject = () => {
    return JSON.parse(localStorage.getItem('AB_Test')) || {};
}

export const getFeatureVersion = (feature) => {
    const abTest = JSON.parse(localStorage.getItem('AB_Test')) || {};
    return abTest[feature] || '';
}

export const getConcatenatedStringOfFeaturesForAnalytics = (featuresUnderTest) => {
    const abTest = JSON.parse(localStorage.getItem('AB_Test')) || {};
    const featuresForAnalytics = [];

    featuresUnderTest.forEach((feature) => {
        if (abTest[feature]) {
            featuresForAnalytics.push(`${feature}-${abTest[feature]}`);
        }
    });

    return featuresForAnalytics.join(' ');
}

export const ABTestRenderer = ({ feature, components }) => {
    const featureVersion = getFeatureVersion(feature);

    const ComponentToRender = components[featureVersion] || null;

    return ComponentToRender ?? null;
};
