

export const getScrollPercent = () => {
  const currentScroll = document.body.scrollTop || document.documentElement.scrollTop;
  const totalHeight = document.body.scrollHeight || document.documentElement.scrollHeight
  const relevantHeight =  totalHeight - document.documentElement.clientHeight;
  const scrollPercent = Math.round( ( currentScroll / relevantHeight ) * 100 );

  return scrollPercent;
}
