import React, { useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { VscMenu, VscClose } from "react-icons/vsc";
import "./Header.scss";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Mixpanel } from '../../mixpanel';

function Header({ showZoomButton }) {
    const [showMenu, setShowMenu] = useState(false);
    const handleClick = () => setShowMenu(!showMenu);
    const closeMobileMenu = () => setShowMenu(false);
    const { company, campaign } = useParams();

    const getPageName = () => {

        if (window.location.pathname.includes('campagin') ||
            window.location.pathname.includes('free') ||
            window.location.pathname == '/')
            return 'home';

        return window.location.pathname.substring(1);
    }

    const scheduleZoomClicked = () => {
        Mixpanel.track('schedule Zoom clicked', { page: getPageName(), button: 'header' });
        // window.ttq.track('InitiateCheckout', {});
    }

    return (
        <>
            <div className="header-background ">
                <div className="header-container container ">
                    {" "}
                    <Link to="/">
                        <img src="/images/all/Betterlogo.svg" className="header-button-right" />
                    </Link>
                    <div className="left-side-mobile">
                        {" "}
                        {showZoomButton && (
                            <AnchorLink onClick={scheduleZoomClicked} offset='88' href='#zoom-schedule'><img
                                src="/images/all/mobile-Video-icone.svg"
                                className="mobile-video-icon"
                            /></AnchorLink>)}

                        <div className="menu-icon" onClick={handleClick}>
                            {showMenu ? <VscClose /> : <VscMenu />}
                        </div>
                    </div>
                    <ul className={showMenu ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <NavLink
                                to="/HowItWorks"
                                className="nav-links"
                                onClick={closeMobileMenu}
                            >
                                איך זה עובד?
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/AboutUs"
                                className="nav-links"
                                onClick={closeMobileMenu}
                            >
                                עלינו
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/FAQ"
                                className="nav-links"
                                onClick={closeMobileMenu}
                            >
                                שאלות ותשובות
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/SecurityandPrivacy"
                                className="nav-links"
                                onClick={closeMobileMenu}
                            >
                                אבטחה ופרטיות
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/Contact"
                                className="nav-links"
                                onClick={closeMobileMenu}
                            >
                                צרו קשר
                            </NavLink>
                        </li>
                        <li>
                            <a
                                href="https://www.comeet.com/jobs/allbetter/F8.00E"
                                className="nav-links"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={closeMobileMenu}
                            >
                                Careers
                            </a>
                        </li>
                        <div className="social-media-wrap">
                            <div className="social-icons">
                                {/*<Link*/}
                                {/*  className="social-icon-link instagram"*/}
                                {/*  to="/"*/}
                                {/*  target="_blank"*/}
                                {/*  aria-label="Instagram"*/}
                                {/*>*/}
                                {/*  <i className="fab fa-instagram" />*/}
                                {/*</Link>*/}
                                <Link
                                    className="social-icon-link twitter"
                                    to={{ pathname: "https://www.linkedin.com/company/betterpfm" }}
                                    target="_blank"
                                    aria-label="LinkedIn"
                                >
                                    <i className="fab fa-linkedin" />
                                </Link>
                                {/*<Link*/}
                                {/*  className="social-icon-link twitter"*/}
                                {/*  to="/"*/}
                                {/*  target="_blank"*/}
                                {/*  aria-label="Twitter"*/}
                                {/*>*/}
                                {/*  <i className="fab fa-twitter" />*/}
                                {/*</Link>*/}
                                <Link
                                    className="social-icon-link facebook"
                                    to={{ pathname: "https://www.facebook.com/people/Better/100086255880240/" }}
                                    target="_blank"
                                    aria-label="Facebook"
                                >
                                    <i className="fab fa-facebook-f" />
                                </Link>
                            </div>
                        </div>
                    </ul>
                    <div className="header-button-wrapper">
                        {showZoomButton && (
                            <AnchorLink
                                onClick={scheduleZoomClicked}
                                offset="88"
                                className="header-button"
                                href="#zoom-schedule"
                            >
                                תיאום היכרות בזום
                            </AnchorLink>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
