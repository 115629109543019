import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useParams } from "react-router-dom";
import CalendarWrapper from '../../../components/library/calendarWrapper/CalendarWrapper';
import Video from '../../../components/library/Video';
import "./NewTech.scss";
import {Mixpanel} from "../../../mixpanel";
import { isMobile } from 'react-device-detect';
import UseTrackScrollHeight from '../../../hooks/UseTrackScrollHeight';
import BannerCalendarWrapper from '../../../components/library/calendarWrapper/BannerCalendarWrapper';

const HowToSave = () => {
  const {source} = useParams();
  Mixpanel.track('article how-to-save pageview',{source});
  
  

  return (
    <>
        <div className="new-tech-container">
          <div className="main-content">
          
            <h1>
              איך עובדת הייטק חסכה בשיחת ZOOM קצרה 719,000 ש"ח בעמלות, בדמי ניהול ובריביות מופרזות? 
            </h1>

            <p>
                הסיפורים של מרינה ואלעד הם סיפורם הקלאסי של ההייטקיסטים המצליחים. מרינה היא מעצבת חווית משתמש בסטארט אפ התל אביבי Simply, כמילניאלית נאמנה מרינה החליפה מספר חברות הייטק במהלך הקריירה שלה ופנתה להצטרף לשירות של Better אחרי ששמעה שיש טכנולוגיה שיכולה לתת לה שקט נפשי בכל הנושאים הפיננסים שאין לה סבלנות אליהם. נתחיל מהסוף, Better חסכו למרינה 719,000 ש”ח בעמלות, בדמי ניהול, בריביות מופרזות ובתשלומים מיותרים תוך חודש מפגישת ההכרות.
            </p>
            
            {
              isMobile ? (
                <Video 
                   configs={{controls: true, autoPlay: true}}
                   poster={"/images/all/video-thumbnail.png"}
                   sourceList={[{
                     src: "https://better-website-static.s3.eu-central-1.amazonaws.com/mobile.mp4",
                     type: "video/mp4"
                   }]}
                />
              ):
              (
                <Video 
                  configs={{controls: true, autoPlay: true}}
                  poster={"/images/all/video-thumbnail.png"}
                  sourceList={[{
                    src: "https://better-website-static.s3.eu-central-1.amazonaws.com/desktop.mp4",
                    type: "video/mp4"
                  }]}
              />
              )
            }

            <p>
                "בתור אחת שלא מבינה בכספים, היה לי קשה מאוד להתחבר לעובדה שחסכו לי 719,000 ש"ח, חלק מהם זה כספים של פנסיה וזה רחוק ממני שנות אור. אבל כשהמומחים של Better הסבירו לי שהסכום הזה מתורגם ל-3,000 ש"ח נוספים שאקבל בכל חודש מרגע מהפרישה, זה היה הרגע הראשון שהחסכון הזה ריגש אותי. 3,000 ש"ח בכל חודש שהייתי משלמת על דמי ניהול ועמלות, זה לא נתפס".
            </p>

            <p>
                אלעד לעומתה הוא ההפך הגמור, כהייטקיסט מזן אחר אשר קורא כל אות במכתב שמגיעה מהבנק, חברות הפנסיה והגמל. במקביל שולט ביד רמה עם אקסל מתוקתק שמתעדכן כל חודש עם כל הסכומים שצבר בקרנות. אלעד יודע כמה הוא חוסך, יודע כמה דמי ניהול הוא משלם וממש לא אוהב "לצאת פראייר", לכן היה ממש מופתע כאשר Better חסכה לו כ-300,000 ש"ח.

            </p>

            <p>
                "אם אני שתופס עצמי כנמצא תמיד בשליטה" אומר אלעד, "ומנהל הכל באקסלים בצורה ברורה כשאני יודע בדיוק כמה יש אצל כל אחד, ועדיין הצליחו לחסוך לי 300,000 ש"ח, אז אני מניח שאנשים שפחות פדנטים ועוסקים בזה על בסיס יומי, כנראה שאפשר לחסוך להם בצורה משמעותית הרבה יותר".
            </p>

            <div className='link-wrapper'>
              <AnchorLink offset='88' href='#zoom-schedule'>
                תאמו היום שיחת הכרות ב-Zoom עם כלכלן של Better והצטרפו לאלפי הייטקיסטים שחוסכים מאות אלפי שקלים בעמלות ובדמי ניהול מוגזמים
              </AnchorLink>
            </div>

            <h2>
                אז איך ניגשים לנושא המורכב הזה של "להבין התנהלות פיננסית?"
            </h2>

            <p>
                מה הצעדים שעלינו לעשות כדי לחסוך כבר היום מאות אלפי שקלים בעמלות מיותרות ובדמי ניהול מוגזמים? מה אפשר לעשות לבד ואיך אפשר לתת לטכנולוגיה לעשות את הכל בשבילכם? השלבים לפניכם.
            </p>
            
            <p>
              <span>
                שלב ראשון - לדעת כמה כסף יש לך ואיפה הוא יושב. 
              </span>
              האם יש לך ביטוח פנסיוני? איך בנויה המשכנתא שלך, מה המצב שלך במס הכנסה, האם מס הכנסה חייב לך כסף? מתחילים מאיסוף החומר בצורה מסודרת.
            </p>
           
            <p>
              <span>
                שלב שני - בדיקה רוחבית של התנאים בשוק.  
              </span>
                אחרי שהבנו מה יש לנו עכשיו נבין אם התנאים שלנו טובים לעומת מה שאפשר לקבל בשוק. זה אומר לבדוק את הריביות בחשבון הבנק, את דמי הניהול בקרן הפנסיה, את קופות הגמל וקרן ההשתלמות. לעשות שיעורי בית, לאסוף חומר ולהשוות את התנאים שלכם.
            </p>

            <p>
              <span>
                שלב שלישי - משא ומתן עם כל הגורמים. 
              </span>
              גילית שאת משלמת דמי ניהול גבוהים יותר מהשוק? גילית שאתה משלם על ביטוחים שלא רלוונטיים עבורך וזוללים לך את הפנסיה? מתחילים לפנות לחברות האשראי, הפנסיה, הבנקים והביטוח, ומבקשים לשפר את התנאים אל למול התנאים הטובים שמצאתם בשוק. מובן שהחברות האלו עושות הכל כדי שהפנייה אליהן תהיה מייגעת, ידרשו את הטופס בפקס ויתנו לכם לחכות 40 דקות בקו עד שהנציג יתפנה.
            </p>

            <p>
              <span>
                שלב רביעי - לעשות את כל התהליך הזה כל שנה מחדש. 
              </span>
              השווקים דינמיים ותחרותיים וההצעות משתפרות ומשתנות כל הזמן. מי שבודק ומשווה את התנאים שלו בצורה עקבית, צפוי לפרוש כשהוא עשיר יותר, אך מבלי לעבוד קשה יותר.
            </p>

            <h2>
                נותנים לטכנולוגיה לעבוד בשבילכם
            </h2>

            <p>
              למרות שאפשר לעשות את הכל לבד, כדאי לתת לטכנולוגיה ולפרסונליזציה של Better לעשות עבורכם את כל השלבים. החל משלב איתור הכספים והמסמכים, דרך ניטור והשוואת התנאים הכי טובים בכל החברות, הביטוחים, הפנסיה, ההשתלמות והגמל מסביב לשעון, ולדעת שבכל רגע אתם מקבלים את ההצעה הטובה ביותר שקיימת בשוק בלי מאמץ. מה שנותר לכם הוא רק לדאוג לעשות את מה שאתם יודעים וזה להמשיך להרוויח טוב ולדעת הכסף שלכם עובד בשבילכם כי מגיע לכם יותר.
            </p>


            <p>
              איך יוצאים לדרך? ההרשמה מהירה, סופר נוחה ומתרחשת בשניות דרך המובייל. קובעים פגישת ZOOM עם מומחה פיננסי של Better שיזין את האלגוריתם לכל הפרטים שיאפשרו לו לעשות את מה שהוא יודע - לחסוך לכם מאות אלפי שקלים. כל פעם שהאלגוריתם שלנו מאתר לכם חסכון, תקבלו הודעה לוואטסאפ שמבהירה את ההזדמנות לחסכון, ומבקשת את אישורכם לביצוע הפעולה.
            </p>

           
            <p>
              חשוב שתדעו ש-Better מחזיקה בכל הרישיונות הנדרשים על ידי בנק ישראל, משרד המשפטים, מועצת רואי החשבון והרשות להגנת הפרטיות. המידע שלכם מוחזק בסטנדרט האבטחה הגבוה ביותר, ונמצא בפיקוח חיצוני קבוע שמפאשר לכם להיות רגועים שלאף אחד אין גישה חוץ מכם ומהגורם המוסמך בחוק.
            </p>
 
          </div>
          <BannerCalendarWrapper Calendar={CalendarWrapper}/> {/**bigsize */}
        </div>
    </>
  )
}

export default HowToSave;