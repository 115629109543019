import React, {useState, Component, Fragment} from "react";
import Expand from "react-expand-animated";
import "../../App.scss";
import "./HowItWorks.scss";
import {Link, NavLink, useParams} from "react-router-dom";
import jsonData from '../qAndA/q&a.json';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Calendar from "../../components/library/calendar";
import {Mixpanel} from '../../mixpanel';
import BannerCalendarWrapper from "../../components/library/calendarWrapper/BannerCalendarWrapper";
import {getABTestingObject} from "../../abTesting";

function HowItWorks() {
    Mixpanel.track('how-it-works pageview');
    const { company, campaign, refId } = useParams();

    const scheduleZoomClicked = () => {
        console.log("scheduleZoomClicked");
        Mixpanel.track('schedule Zoom clicked', {page:'how-it-works', button: 'main', company, campaign, refId, ...getABTestingObject()});
        
    }

    return (
        <>
            {/*home container 100% width */}
            <div className="howitwork-container">
                {/*section-presentation container that hold the pack ground and right,left side */}
                <div className="section-presentation">
                    {/*container,row custom container for perfect aline*/}
                    <div className="section-1 row container">
                        {/*right side container holder */}
                        <div className="right-side col-7">
                            <img src="/images/all/Howitwork-Section1-image.png" alt="circular"/>
                        </div>
                        {/*left side container holder */}
                        <div className="left-side  col-5">
                            <h2 className="title"> שירות אנושי, טכנולוגיה מאפשרת</h2>

                            <div className="sup-title">
                                <p>
                                    <img
                                        className="better-min"
                                        src="/images/all/Betterlogo-min.svg"
                                    />
                                    הוקמה על ידי מומחי פיננסים וטכנולוגיה, כל אחד עם יותר מ-15
                                    שנות ניסיון.
                                </p>

                                <p>
                                    פיתחנו טכנולוגיה ייחודית שעושה את כל העבודה עבור הלקוחות שלנו,
                                    כך שהם לא צריכים לעשות כלום:
                                    <br/> אנחנו משיגים את המידע באופן אוטומטי וסורקים בכל רגע
                                    נתון איך אפשר לחסוך כסף בכל מקום - במס, בביטוח, בפנסיה,
                                    במשכנתא, בהשקעות, ובגדול - בכל תחום שקשור לפיננסים.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-img">
                        <img src="/images/all/Howitwork-Section1-mobile.png" alt="circular"/>
                    </div>
                </div>
                {/*the blue container 100% width */}
                <div className="section-save ">
                    {/*container,row custom container for perfect aline*/}
                    <div className="colored-box-Background">
                        <div className="container row">
                            {/*right side container holder */}
                            <div className="right-side col-6">
                                <h2 className="title ">חיסכון משמעותי בראש שקט</h2>
                                <p className="sup-title">
                                    חסכנו עד היום סכום ממוצע של 200,000 ש"ח במצטבר ללקוח בעמלות
                                    ובדמי ניהול, ולכן אנחנו מניחים שנוכל למצוא כאלה חסכונות גם
                                    בשבילכם.
                                </p>
                                <AnchorLink onClick={scheduleZoomClicked} className="zoom-button-gray" offset='88'
                                            href='#zoom-schedule'>תיאום היכרות בזום</AnchorLink>
                            </div>
                            {/*left side container holder */}
                            <div className="left-side col-6">
                                <img
                                    src="/images/all/Howitwork-Section2-image.png"
                                    alt="circular"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* section-Security and privacy*/}
                <div className="section-security-and-privacy">
                    {/*container,row custom container for perfect aline*/}
                    <div className="container row">
                        {/* right side */}
                        <div className="right-side col-5">
                            <div className="company-logo">
                                <div className="company-logo-1">
                                    <img src="/images/all/aws.png"/>
                                </div>
                                <div className="company-logo-2">
                                    {" "}
                                    <img src="/images/all/Company-logo-2.png"/>
                                </div>
                                <div className="company-logo-3">
                                    {" "}
                                    <img src="/images/all/Company-logo-5.png"/>
                                </div>
                                <div className="company-logo-4">
                                    {" "}
                                    <img src="/images/all/Company-logo-4.png"/>
                                </div>
                            </div>

                            <div className="security-icon">
                                <div>
                                    {" "}
                                    <img src="/images/all/Security-icone-4.svg"/>
                                </div>
                                <div>
                                    {" "}
                                    <img src="/images/all/Security-icone-3.svg"/>
                                </div>
                                <div>
                                    <img src="/images/all/Security-icone-2.svg"/>
                                </div>
                                <div>
                                    {" "}
                                    <img src="/images/all/Security-icone-1.svg"/>
                                </div>
                            </div>
                        </div>
                        {/* left side */}
                        <div className="left-side col-6">
                            <h2 className="title">אבטחה ופרטיות</h2>
                            <p className="sup-title">
                                {" "}
                                <img className="better-min" src="/images/all/Betterlogo-min.svg"/>
                                מעניקה שירות אישי וקשוב במיוחד ללקוחותיה, וגאה להיות מפוקחת על
                                ידי 4 רגולטורים פיננסים: בנק ישראל, רשות שוק ההון, ביטוח
                                וחיסכון, משרד המשפטים ומס ההכנסה.
                            </p>
                            {/* mobile logo */}
                            <div className="company-logo-mobile">
                                <div className="company-logo-mobile-1">
                                    <img src="/images/all/Company-logo-mobile-1.png"/>
                                </div>
                                <div className="company-logo-mobile-2">
                                    {" "}
                                    <img src="/images/all/Company-logo-mobile-2.png"/>
                                </div>
                                <div className="company-logo-mobile-3">
                                    {" "}
                                    <img src="/images/all/Company-logo-mobile-5.png"/>
                                </div>
                                <div className="company-logo-mobile-4">
                                    {" "}
                                    <img src="/images/all/Company-logo-mobile-4.png"/>
                                </div>
                            </div>
                            <p className="sup-title">
                                לפי הוראותיהם, כל המידע הפיננסי מוצפן ומופרד באופן הרמטי ממערכות
                                עובדי החברה, כך שרק לכם ולמערכת הטכנולוגית יש גישה אליו. אף אחד
                                מאיתנו לא יכול לגשת למידע בלי אישורכם!
                            </p>
                            <div className="security-icon-mobile">
                                <div>
                                    {" "}
                                    <img src="/images/all/Security-icone-4.svg"/>
                                </div>
                                <div>
                                    {" "}
                                    <img src="/images/all/Security-icone-3.svg"/>
                                </div>
                                <div>
                                    <img src="/images/all/Security-icone-2.svg"/>
                                </div>
                                <div>
                                    {" "}
                                    <img src="/images/all/Security-icone-1.svg"/>
                                </div>
                            </div>
                            <p className="sup-title">
                                <img className="better-min" src="/images/all/Betterlogo-min.svg"/>
                                מפעילה אמצעי אבטחת מידע מתקדמים אשר בשימוש של החברות הפיננסיות
                                הגדולות בישראל ובעולם.
                            </p>
                        </div>
                    </div>
                </div>
                {/* section-commit */}
                <div className="section-commit">
                    <div className="container row">
                        <div className="right-side col-6">
                            <h1 className="title-3">מתחייבים לחיסכון - <br/>ועומדים בכך</h1>
                            <p className="sup-title">
                                <strong>
                                    אחרי אלפי לקוחות מרוצים אנו בטוחים בכך שנביא לכם ערך משמעותי
                                    ולפיכך מוכנים להתחייב:
                                </strong>
                                <br/>אם לא נצליח לחסוך לכם כסף, לא נגבה מכם כל תשלום.
                                <br className="mobile-brake"/>
                                <br className="mobile-brake"/>
                                 בלי התחייבות ובלי אותיות קטנות.
                                <br/>
                                כי אנחנו בצד שלכם.
                            </p>
                        </div>
                        <div className="left-side col-6">
                            <img
                                className="web"
                                src="/images/all/Howitwork-Section4-image.png"
                                alt="circular"
                            />
                            <img
                                className="mobile"
                                src="/images/all/Howitwork-Section4-image-mobile.png"
                                alt="circular"
                            />
                        </div>
                    </div>
                </div>
                {/* Q&A */}
                <div className="section-q-and-a container">
                    <h1 className="title">שאלות ותשובות</h1>

                    <div className="accordion">
                        {[jsonData[0], jsonData[1], jsonData[3], jsonData[4]].map((item, i) => <div class="c">
                                <input type="checkbox" id={'faq-' + i}/>
                                <h1>
                                    <label for={'faq-' + i}>{item.q}</label>
                                </h1>
                                <div className="p">
                                    <p>{item.a}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <BannerCalendarWrapper Calendar={Calendar} image="/images/all/Howitwork-Calendar.png"/>
        </>
    );
}

export default HowItWorks;
