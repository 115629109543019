import React from 'react';

const Video = ( props ) => {

    const { configs, poster, width, sourceList, timeUpdate } = props;

    return (
        <div className='video-wrapper'>
            <video  
                autoPlay={configs.autoPlay} 
                controls={configs.controls} 
                loop={configs.loop} 
                muted={configs.muted} 
                poster={poster}
                style={{ width: width || "100%" }}
                onTimeUpdate={timeUpdate || null}
            >
                {
                    sourceList.map( (video, i) => <source src={video.src} type={video.type} key={i} /> )
                }
                Sorry, your browser doesn't support this video.
            </video>
        </div>
    )
}

export default Video;