import React, { useState, useRef } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useParams } from "react-router-dom";
import FAQ from "../../../components/library/fAQ/FAQ";
import CalendarWrapper from "../../../components/library/calendarWrapper/CalendarWrapper";
import Step from "../../../components/library/steps/Step";
import SavingsItem from "../../../components/library/savings/SavingsItem";
import LicenseItem from "../../../components/library/license/LicenseItem";
import { Mixpanel } from "../../../mixpanel";
import UseTrackScrollHeight from "../../../hooks/UseTrackScrollHeight";
import { isMobile } from 'react-device-detect';
import "./NewTechSm.scss";
import Video from "../../../components/library/Video";
import BannerCalendarWrapper from "../../../components/library/calendarWrapper/BannerCalendarWrapper";
import { getFeatureVersion } from "../../../abTesting";


const NewTechSm = () => {
  const { source, webCampaign } = useParams();
  const [achievedVideoTarget, setAchievedVideoTarget] = useState(false);

  const harb = window.location.href.includes("harb");
  const masleka = window.location.href.includes("masleka");
  const pension = window.location.href.includes("pension");

  const scheduleZoomClicked = () => {
    Mixpanel.track('schedule Zoom clicked', { page: source, button: 'header', landingPageVersion: getFeatureVersion('landingPageVersion') });
    
  }


  Mixpanel.track('social media landing page view', { source, landingPageVersion: getFeatureVersion('landingPageVersion') });

  const onTimeUpdateVideo = (e) => {
    if (e.target.currentTime > 20 && !achievedVideoTarget && source?.toLowerCase().includes('taboola')) {
      setAchievedVideoTarget(true);
      
    }
  }

  const RightTopText = () => {
    if (harb) {
      return <span>דוח הר הביטוח ללא עלות</span>
    }
    if (masleka) {
      return <span>תחסכו עמלות ודמי ניהול מוגזמים</span>
    }
    if (pension) {
      return <span>להשוואת קרנות פנסיה</span>
    }
    return <span>סריקת חסכונות מתמשכת בחינם</span>
  }


  const MainText = () => {
    if (harb) {
      return <>
        <h1>עושים סדר בהר הביטוח: איתור כפל ביטוחי והוזלת תשלומי הביטוח ללא עלות</h1>
        <p>
          הטכנולוגיה של
          <img
            src="/images/all/Betterlogo-min-white.svg"
            alt="better log"
          />
          מתאימה לאנשים שהכסף שלהם חשוב להם ורוצים לשפר את
          הסטטוס שלהם בלי מאמץ. האלגוריתם שלנו עובד בשבילכם מסביב לשעון,
          מטפל בנושאים הפיננסיים שאתם לא רוצים לגעת בהם וחוסך לכם בממוצע
          261,000 ש”ח.
        </p>
      </>
    }

    if (masleka) {
      return (
        <>
          <h1>הרבה יותר מדו״ח מסלקה פנסיונית</h1>
          <p>
            הטכנולוגיה שלנו תחסוך לכם מאות אלפי שקלים בחיסכון הפנסיוני ללא עלות וללא התחייבות.
            <img
              src="/images/all/Betterlogo-min-white.svg"
              alt="better log"
            />
            מתאימה לאנשים שהכסף שלהם חשוב להם ורוצים לשפר את
            הסטטוס שלהם בלי מאמץ. האלגוריתם שלנו עובד בשבילכם מסביב לשעון,
            מטפל בנושאים הפיננסיים שאתם לא רוצים לגעת בהם וחוסך לכם בממוצע
            261,000 ש”ח.
          </p>
        </>
      )
    }

    if (pension) {
      return (
        <>
          <h1>השוואת קרנות פנסיה עם מומחה פנסיוני מקצועי</h1>
          <p>
            הטכנולוגיה של
            <img
              src="/images/all/Betterlogo-min-white.svg"
              alt="better log"
            />
            מתאימה לאנשים שהכסף שלהם חשוב להם ורוצים לשפר את
            הסטטוס שלהם בלי מאמץ. האלגוריתם שלנו עובד בשבילכם מסביב לשעון,
            מטפל בנושאים הפיננסיים שאתם לא רוצים לגעת בהם וחוסך לכם בממוצע
            261,000 ש”ח.
          </p>
        </>
      )
    }

    return (
      <>
        <h1>תנו לטכנולוגיה לסדר לכם את התנאים הכי טובים בשוק.</h1>
        <p>
          הטכנולוגיה של
          <img
            src="/images/all/Betterlogo-min-white.svg"
            alt="better log"
          />
          מתאימה לאנשים שהכסף שלהם חשוב להם ורוצים לשפר את
          הסטטוס שלהם בלי מאמץ. האלגוריתם שלנו עובד בשבילכם מסביב לשעון,
          מטפל בנושאים הפיננסיים שאתם לא רוצים לגעת בהם וחוסך לכם בממוצע
          261,000 ש”ח.
        </p>
      </>
    )
  }

  return (
    <>
      <div className="newTechSm-container">
        <section className="main">
          <div className="content-container">
            <div className="top-line desktop-only">
              <div className="right-top">
                <AnchorLink
                  onClick={scheduleZoomClicked}
                  offset="88"
                  className="schedule-button"
                  href="#zoom-schedule"
                >
                  תאמו היכרות בזום
                </AnchorLink>

                <RightTopText />
              </div>
              <img
                className="logo"
                src="/images/all/Betterlogo-min-white.svg"
                alt="better log"
              />
            </div>
            <img
              className="logo mobile-only"
              src="/images/all/Betterlogo-min-white.svg"
              alt="better log"
            />

            <MainText />

            <div className="link-wrapper desktop-only">
              <AnchorLink
                offset="88"
                className="schedule-button"
                href="#zoom-schedule"
              >
                תאמו היכרות בזום &gt;&gt;
              </AnchorLink>
            </div>

            <AnchorLink
              offset="88"
              className="schedule-button mobile-only"
              href="#zoom-schedule"
            >
              תאמו היכרות בזום &gt;&gt;
            </AnchorLink>

            <div className="brands">
              <div>הלקוחות שלנו עובדים ב:</div>
              <img
                className="customers-logo teva"
                src="/images/all/teva.png"
                alt="teva logo"
              />
              <img
                className="customers-logo"
                src="/images/all/microsoft.png"
                alt="microsoft logo"
              />
              <img
                className="customers-logo"
                src="/images/all/deloitte.png"
                alt="deloitte logo"
              />
            </div>
          </div>
        </section>

        <section className="video-section">
          <div className="video-title">
            צפו בלקוחות מרוצים שחסכנו להם מאות אלפי שקלים
          </div>

          {
            isMobile ? (
              <Video
                timeUpdate={onTimeUpdateVideo}
                configs={{ controls: true, autoPlay: true }}
                poster={"/images/all/video-thumbnail.png"}
                sourceList={[{
                  src: "https://better-website-static.s3.eu-central-1.amazonaws.com/landingpage-video.mp4",
                  type: "video/mp4"
                }]}
              />
            ) :
              (
                <Video
                  timeUpdate={onTimeUpdateVideo}
                  configs={{ controls: true, autoPlay: true }}
                  poster={"/images/all/video-thumbnail.png"}
                  sourceList={[{
                    src: "https://better-website-static.s3.eu-central-1.amazonaws.com/landingpage-video.mp4",
                    type: "video/mp4"
                  }]}
                />
              )
          }
        </section>

        <section className="howItWorks">
          <div className="content-container">
            <h2 className="mobile-only">איך זה עובד?</h2>
            <img
              className="mobile-only"
              src="/images/all/how_it_works_mobile.png"
              alt=""
            />

            <div className="steps-right">
              <h2 className="desktop-only">איך זה עובד?</h2>
              <Step
                number={"1"}
                title={"נפגשים ב-ZOOM להכרות"}
                text={
                  "שיחת זום עם כלכלן שלנו, מקבלים את אישורכם לאסוף את המסמכים המעצבנים ומשם עוברים לווטסאפ."
                }
              />
              <Step
                number={"2"}
                title={"עובדים בשבילכם 24/7"}
                text={
                  "הטכנולוגיה שלנו סורקת באופן מתמשך את כל התנאים הטובים בשוק ומזהה הזדמנויות לחסכון, ואתם יכולים להיות רגועים שאתם תמיד מקבלים את התנאים הכי טובים."
                }
              />
              <Step
                number={"3"}
                title={"חוסכים עבורכם"}
                text={
                  "בכל פעם שנזהה חסכון נשלח לכם הודעת ווטסאפ עם כל הפרטים ורק אחרי אישור מפורש שלכם נבצע את החסכון עבורכם."
                }
              />
            </div>
            <div className="image-left">
              <img
                className="desktop-only"
                src="/images/all/how_it_works.png"
                alt=""
              />
            </div>

          </div>
        </section>

        <section className="customers">
          <div className="content-container">
            <h2 className="mini-title">
              הלקוחות שלנו חוסכים בממוצע
              <span>261,000 &#8362;</span>
              בחודש הראשון
            </h2>

            <p>
              הלקוחות שלנו הם אנשים כמוך שהכסף שלהם חשוב להם ורוצים לשפר את
              הסטטוס שלהם בלי מאמץ, האלגוריתם שלנו עושה את הכל בשבילכם ומטפל
              בנושאים הפיננסיים שאתם לא רוצים לגעת בהם מסביב לשעון כדי שתוכלו
              להתעסק במה שאתם טובים בו וחוסך לכם בממוצע 261,000 ש”ח.
            </p>

            <div className="savings-wrapper">
              <SavingsItem
                customersImg={
                  "/images/all/diana.png"
                }
                customersLogo={
                  "/images/all/tiktok.png"
                }
                savingsText={{
                  name: "דיאנה שפקובסקי",
                  title: "מנהלת תיקי לקוחות",
                  saved: "חסכה 150,000 ₪",
                }}
              />
              <SavingsItem
                customersImg={
                  "/images/all/elad.png"
                }
                customersLogo={
                  "/images/all/biot.png"
                }
                savingsText={{
                  name: "אלעד פישר",
                  title: "מנהל מכירות טכנולוגי",
                  saved: "חסך 300,000 ₪",
                }}
              />

              <SavingsItem
                customersImg={
                  "/images/all/raz.png"
                }
                customersLogo={
                  "/images/all/opal.png"
                }
                savingsText={{
                  name: "רז ראש",
                  title: "מנהל אבטחת מידע",
                  saved: "חסך 792,000 ₪",
                }}
              />
            </div>
          </div>
        </section>

        <section className="action">
          <div className="content-container">
            <h2 className="mini-title">תנו לטכנולוגיה שלנו לחסוך בשבילכם</h2>

            <h3>
              רוצה להיות בשליטה בלי מאמץ ולחסוך מאות אלפי שקלים בעמלות מיותרות
              ודמי ניהול מוגזמים?
              <span>
                תאמו היום שיחה עם מומחה פיננסי של
                <img src="/images/all/Betterlogo-min-white.svg" alt="" />
                ותהנו משירות סריקת החסכונות המתמשך שלנו בחינם.
              </span>
            </h3>

            <AnchorLink
              offset="88"
              className="schedule-button"
              href="#zoom-schedule"
            >
              תאמו היכרות בזום
              <span>
                &gt;&gt;
              </span>

            </AnchorLink>
          </div>
        </section>

        <section className="recommended">
          <div className="content-container">
            <h2 className="mini-title">
              אתם בחברה טובה, 200 חברות הייטק מהמובילות בישראל המליצו לעובדים
              שלהן להצטרף ל
              <img src="/images/all/Betterlogo-min.svg" alt="" />
            </h2>

            <div className="recommended-logos">
              <img
                src="/images/all/trieye.png"
                alt=""
              />
              <img
                src="/images/all/fyber.png"
                alt=""
              />
              <img
                src="/images/all/papaya.png"
                alt=""
              />

              <img
                src="/images/all/morning.png"
                alt=""
              />
              <img
                src="/images/all/sensi.png"
                alt=""
              />
              <img
                src="/images/all/silentium.png"
                alt=""
              />
              <img
                src="/images/all/pixellot.png"
                alt=""
              />

              <img
                src="/images/all/duda.png"
                alt=""
              />
              <img
                src="/images/all/develeap.jpg"
                alt=""
              />
              <img
                src="/images/all/gia.png"
                alt=""
              />
              <img
                src="/images/all/hotelmize.png"
                alt=""
              />
              <img
                src="/images/all/wiliot.png"
                alt=""
              />
              <img
                src="/images/all/supercom.jpg"
                alt=""
              />
              <img
                src="/images/all/quali.png"
                alt=""
              />
              <img
                src="/images/all/intuition.png"
                alt=""
              />

            </div>
          </div>
        </section>

        <section className="security">
          <div className="content-container">
            <h2 >אבטחה מקסימלית בהתאם לרגולצייה הפיננסית</h2>

            <div className="license-wrapper">
              <div className="right-license">
                <LicenseItem
                  image={"/images/all/state-of-israel.png"}
                  text={
                    "מרשם מיופי כוח בתמורה של בנק ישראל - מיופה כוח בתמורה"
                  }
                />

                <LicenseItem
                  image={"/images/all/justice_office.png"}
                  text={
                    "משרד המשפטים - מאגר מידע רשום"
                  }
                />

                <LicenseItem
                  image={"/images/all/mas.png"}
                  text={
                    "מועצת יועצי המס - רישיון יועץ מס"
                  }
                />

                <LicenseItem
                  image={"/images/all/pension_license.png"}
                  text={
                    "רישיון שוק ההון, ביטוח וחיסכון - בעל רישיון פנסיוני"
                  }
                />
              </div>

              <div className="left-image desktop-only">
                <img
                  src="/images/all/security.png"
                  alt="security lock"
                />
              </div>

              <img
                className="mobile-only"
                src="/images/all/security-mobile.png"
                alt="security lock"
              />

            </div>
            <AnchorLink
              offset="88"
              className="schedule-button"
              href="#zoom-schedule"
            >
              תאמו היכרות בזום
              <span>
                &gt;&gt;
              </span>
            </AnchorLink>
          </div>
        </section>

        <section className="faq">
          <FAQ />
        </section>

        <section className="calendar">
          <BannerCalendarWrapper webCampaign={webCampaign} Calendar={CalendarWrapper} />
        </section>

      </div>
    </>
  );
};

export default NewTechSm;
