import React, {useEffect, useRef, useState} from "react";
import "./counter.scss"

function Counter() {
    const [totalSavings, setTotalSavings] = useState(0);

    const getTotalSavings = async () => {
        try {
            const result = await fetch("https://p.allbetter.co.il/api/public/total-savings");
            const json = await result.json();

            setTotalSavings(json.totalSavings);

        } catch (err) {
            console.log("Can't get total savings")
        }
    }

    const addCommas = (num)=> {
        if (num == undefined)
            return '';

        return Math.round(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    useEffect(() => {
        getTotalSavings();
    }, []);
    return (
        <>
            {totalSavings?<div className="section-counter">
                <img className="decoration" src="/images/all/decoration.png" />
                <div className="title">עד כה חסכנו ללקוחותינו</div>
                <div className="numbers">
                    {addCommas(totalSavings).split('').reverse().map((numStr, index) =>
                        numStr === ','?<div className="comma">
                            <img className="number" src="/images/all/comma.svg" />
                        </div>:
                        <div className={`number-wrapper ${index === 0?'last':''}`}>
                            <img className="number" src={`/images/all/number-${numStr}.png`} />
                        </div>
                    )}
                    <img className="shekel" src="/images/all/shekel.png" />
                </div>
            </div>:null}
        </>)
}

export default Counter;