import React from "react";
import "../../App.scss";
import "./QANDA.scss";
// import CalendarSection from "../../components/library/CalendarSection";
import Calendar from "../../components/library/calendar";
import jsonData from './q&a.json';
import { Mixpanel } from '../../mixpanel';
import BannerCalendarWrapper from "../../components/library/calendarWrapper/BannerCalendarWrapper";

function QANDA() {
    Mixpanel.track('Q&A pageview');
  return (
    <>
      <div className="qanda-container">
        {/* qanda*/}
        <div className="question container">
          <h1 className="title">נשמח לענות על כל שאלה</h1>
          {/* accordion */}
          <div className="accordion">
            {jsonData.map((item,i)=><div className="c">
              <input type="checkbox" id={'faq-'+i}/>
              <h1>
                <label for={'faq-'+i}>{item.q}</label>
              </h1>
              <div className="p">
                <p>{item.a}</p>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
      <BannerCalendarWrapper Calendar={Calendar} image="/images/all/QANDA-Calendar.png" />
    </>
  );
}

export default QANDA;
